import { useEffect, useState, type FC } from "react";
import { Button, Checkbox, Label } from "flowbite-react";
import { Dialog } from "../..//molecules/Dialog";
import { useTranslation } from "react-i18next";
import { StyledTextInput } from "../../atoms/Inputs";
import { validateEmail } from "../../../helpers/validationHelper";

export interface SendInvoiceProp {
  show: boolean;
  isLoading: boolean;
  prefilledEmail?: string;
  toggleModal: () => void;
  confirmButton: (email: string) => void;
}
interface DialogContentProps {
  prefilledEmail?: string;
  toggleModal: () => void;
  confirmButton: (email: string) => void;
}
export const SendInvoice: FC<SendInvoiceProp> = (props) => {
  return (
    <Dialog
      size="md"
      denseHeader
      show={props.show}
      toggleModal={props.toggleModal}
      hideButtons={true}
      content={
        <DialogContent
          prefilledEmail={props.prefilledEmail}
          toggleModal={props.toggleModal}
          confirmButton={props.confirmButton}
        />
      }
    />
  );
};

const DialogContent: FC<DialogContentProps> = (props) => {
  const { t } = useTranslation(["common", "invoice", "stats"]);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [validationFailed, setValidationFailed] = useState(false);
  const [email, setEmail] = useState<string>("");

  const validateAndSend = () => {
    if (!validateEmail(email)) {
      setValidationFailed(true);
    } else {
      setValidationFailed(false);
      props.confirmButton(email);
    }
  };

  useEffect(() => {
    if (props.prefilledEmail) {
      setEmail(props.prefilledEmail);
    }
  }, [props.prefilledEmail]);
  return (
    <div className="flex flex-col items-start justify-between gap-4">
      <p className="text-xl font-medium text-gray-900">
        {t("invoice:actions.edit_items.dialog_title")}
      </p>
      <p className="text-md text-lgb-grey-500">
        {t("invoice:actions.edit_items.dialog_description")}
      </p>

      <div className="w-full">
        <Label
          className="dark:text-lgb-grey-400"
          htmlFor="reciever"
          value={t("invoice:actions.edit_items.dialog_input_label")}
        />
        <form className="w-full">
          <StyledTextInput
            id="reciever"
            placeholder={t(
              "invoice:actions.edit_items.dialog_input_placeholder",
            )}
            type="text"
            onInputChanged={(e) => setEmail(e)}
            defaultValue={props.prefilledEmail}
            validation={{
              id: "weight",
              show: validationFailed,
              isInvalid: validationFailed,
              errorMessage: t("validation:email"),
            }}
          />
        </form>
      </div>

      <div className="flex items-center gap-2 py-4 px-4 bg-lgb-grey-100 rounded">
        <Checkbox
          id="terms"
          className="text-lgb-primary dark:text-lgb-primary-lighten"
          onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
        />
        <Label htmlFor="terms" className="text-lgb-grey-500 font-normal">
          {t("invoice:actions.edit_items.dialog_input_checkbox")}
        </Label>
      </div>

      <div className="flex gap-4">
        <Button color="gray" onClick={() => props.toggleModal()}>
          {t("common:close")}
        </Button>
        <Button
          onClick={validateAndSend}
          color="primary"
          disabled={!hasAcceptedTerms}
        >
          {t("common:send")}
        </Button>
      </div>
    </div>
  );
};
