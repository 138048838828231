import { DocumentProps, PDFViewer } from "@react-pdf/renderer";
import { FC } from "react";

export interface PDFRendererProp {
  content: React.ReactElement<DocumentProps>;
  close: () => void;
}

export const PDFRenderer: FC<PDFRendererProp> = (props) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center z-50"
      onClick={() => props.close()}
    >
      <div
        className="bg-white rounded shadow-lg overflow-hidden"
        style={{ width: "80%", height: "90%" }}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
      >
        <PDFViewer style={{ width: "100%", height: "100%" }}>
          {props.content}
        </PDFViewer>
      </div>
    </div>
  );
};

export default PDFRenderer;
