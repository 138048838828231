import type { FC } from "react";
import { TextInput } from "flowbite-react";
import { InputValidationResult } from "../../../types";
import { useTranslation } from "react-i18next";

interface TextInputProps {
  id: string;
  placeholder?: string;
  defaultValue?: string | number;
  required?: boolean;
  type?: string;
  validation?: InputValidationResult;
  disabled?: boolean;
  onInputChanged?: (input: string) => void;
}

export const StyledTextInput: FC<TextInputProps> = function (
  props: TextInputProps,
) {
  const { t } = useTranslation(["common"]);

  const getValidationMessage = () => {
    if (props.validation?.isMissing) {
      return props.validation?.fieldName + " " + t("common:errors.required");
    }
    if (props.validation?.isInvalid) {
      if (props.validation?.errorMessage !== undefined) {
        return props.validation?.errorMessage;
      }
      return props.validation?.fieldName + " " + t("common:errors.invalid");
    }
    return "";
  };

  const customTheme = {
    field: {
      input: {
        base: "dark:bg-lgb-dark-background dark:text-white w-full",
      },
    },
  };

  return (
    <TextInput
      id={props.id}
      name={props.id}
      theme={customTheme}
      placeholder={props.placeholder ?? ""}
      defaultValue={props.defaultValue ?? ""}
      type={props.type ?? "text"}
      required={props.required ?? false}
      disabled={props.disabled ?? false}
      color={
        props.validation?.show &&
        (props.validation?.isMissing || props.validation?.isInvalid)
          ? "failure"
          : ""
      }
      helperText={props.validation?.show ? getValidationMessage() : ""}
      onChange={(e) =>
        props.onInputChanged ? props.onInputChanged(e.target.value) : null
      }
    />
  );
};
