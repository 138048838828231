import { Invoice } from "../../models";
import { ICustomer, Timestamp } from "../../types";
import { IInvoiceStatus, IInvoiceItem } from "../../types/Invoice";

/**
 * ----- MOCK CUSTOMERS ------
 */
const customer1: ICustomer = {
  id: "customer-001",
  workspaceId: "workspace-001",
  name: "Alice Smith",
  contactEmail: "alice.smith@example.com",
  isCompany: true,
  primaryAddress: {
    addressLine: "Flomgata 190",
    postCode: "5059",
    city: "Bergen",
    countryCode: "NO",
  },
};

const customer2: ICustomer = {
  id: "customer-002",
  workspaceId: "workspace-002",
  firstName: "Pedro",
  lastName: "Companyson",
  contactEmail: "contact@techinnovations.com",
  isCompany: false,
  primaryAddress: {
    addressLine: "Flomgata 190",
    postCode: "5059",
    city: "Bergen",
    countryCode: "NO",
  },
};

const customer3: ICustomer = {
  id: "customer-003",
  workspaceId: "workspace-003",
  name: "John Doe",
  contactEmail: "john.doe@example.com",
  isCompany: true,
  primaryAddress: {
    addressLine: "Flomgata 190",
    postCode: "5059",
    city: "Bergen",
    countryCode: "NO",
  },
};

const customer4: ICustomer = {
  id: "customer-004",
  workspaceId: "workspace-004",
  name: "Global Solutions LLC",
  contactEmail: "info@globalsolutions.com",
  isCompany: true,
  organizationNumber: "987654321",
  primaryAddress: {
    addressLine: "Flomgata 190",
    postCode: "5059",
    city: "Bergen",
    countryCode: "NO",
  },
};

/**
 * ----- MOCK ITEMS ------
 */
const items1: IInvoiceItem[] = [
  {
    itemTitle: "Extra driver",
    quantity: 5,
    price: 200.0,
    discount: 10,
    vat: 25,
  },
  {
    itemTitle: "Pallet of Bricks",
    quantity: 10,
    price: 150.0,
    discount: 5,
    vat: 25,
  },
  { itemTitle: "Couches", quantity: 5, price: 600.0, vat: 25 },
  { itemTitle: "Planke - 4m", quantity: 4, price: 150.0, vat: 25 },
];

const items2: IInvoiceItem[] = [
  { itemTitle: "Mattresses", quantity: 20, price: 200.0, discount: 10 },
  { itemTitle: "Dining Tables", quantity: 3, price: 400.0, vat: 25 },
];

const items3: IInvoiceItem[] = [
  { itemTitle: "Wooden Beams", quantity: 15, price: 100.0 },
  {
    itemTitle: "Kitchen Appliances",
    quantity: 4,
    price: 800.0,
    discount: 15,
    vat: 25,
  },
];

const items4: IInvoiceItem[] = [
  { itemTitle: "Boxes of Clothing", quantity: 25, price: 50.0, vat: 25 },
  { itemTitle: "Bicycles", quantity: 10, price: 300.0 },
];

/**
 * ----- MOCK INVOICES ------
 */
const invoice1 = new Invoice({
  id: "invoice-001",
  sentDate: Timestamp.now(),
  paymentInfo: {
    kid: "00112233",
    accountNumber: "2134.23.12345",
    dueDate: Timestamp.now(),
  },
  emissions: {
    emissionsInKg: 150,
  },
  invoiceEmail: "alice.smith@example.com",
  invoiceId: 123112,
  relatedOrderId: 234523,
  customer: customer1,
  status: IInvoiceStatus.Sent,
  createdAt: Timestamp.now(),
  items: items1,
});

const invoice2 = new Invoice({
  id: "invoice-002",
  sentDate: Timestamp.now(),
  paymentInfo: {
    kid: "00445566",
    accountNumber: "3421.32.63433",
    dueDate: Timestamp.now(),
  },
  emissions: {
    emissionsInKg: 200,
  },
  invoiceEmail: "contact@techinnovations.com",
  invoiceId: 121341,
  relatedOrderId: 237543,
  customer: customer2,
  status: IInvoiceStatus.Draft,
  createdAt: Timestamp.now(),
  items: items2,
});

const invoice3 = new Invoice({
  id: "invoice-003",
  sentDate: Timestamp.now(),
  paymentInfo: {
    kid: "00778899",
    accountNumber: "2312.52.25123",
    dueDate: Timestamp.now(),
  },
  emissions: {
    emissionsInKg: 250,
  },
  invoiceEmail: "john.doe@example.com",
  invoiceId: 231512,
  relatedOrderId: 675345,
  customer: customer3,
  status: IInvoiceStatus.Sent,
  createdAt: Timestamp.now(),
  items: items3,
});

const invoice4 = new Invoice({
  id: "invoice-004",
  sentDate: Timestamp.now(),
  paymentInfo: {
    kid: "11223344",
    accountNumber: "1234.86.35231",
    dueDate: Timestamp.now(),
  },
  emissions: {
    emissionsInKg: 300,
  },
  invoiceEmail: "info@globalsolutions.com",
  invoiceId: 532123,
  relatedOrderId: 876456,
  customer: customer4,
  status: IInvoiceStatus.Sent,
  createdAt: Timestamp.now(),
  items: items4,
});

export const mockInvoices = [invoice1, invoice2, invoice3, invoice4];
