/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../../components";
import { Order } from "../../../models";
import { Button } from "flowbite-react";
import { ProductManagement } from "../../../pages/orders/create-order";
import { IOrderProduct } from "../../../types";
import { ProductSelectionRef } from "../../../pages/orders/create-order/steps/ProductManagement";

interface EditCargoProp {
  show: boolean;
  order: Order;
  isLoading: boolean;
  edit: (route: IOrderProduct[]) => void;
  close: () => void;
}

export const EditCargo: FC<EditCargoProp> = function (props: EditCargoProp) {
  const { t } = useTranslation(["common", "orders"]);
  const [validationFailed, setValidationFailed] = useState(false);

  const productSelectionRef = useRef<ProductSelectionRef>(null);

  function editCargo(cargo: IOrderProduct[]) {
    if (!Order.validateCargo(cargo)) {
      setValidationFailed(true);
    } else {
      setValidationFailed(false);
      props.edit(cargo);
      props.close();
    }
  }

  const handleUpdate = () => {
    if (productSelectionRef.current) {
      productSelectionRef.current.updateOrderProducts();
    }
  };

  return (
    <Dialog
      size="7xl"
      title={t("orders:ongoing_orders.edit_cargo")}
      show={props.show}
      toggleModal={() => props.close()}
      hideButtons
      content={
        <>
          <div className="text-lgb-grey-600 text-base pb-4 ">
            {t("orders:ongoing_orders.edit_cargo_description")}
          </div>
          <div className="pb-16">
            <ProductManagement
              ref={productSelectionRef}
              key={
                props.order.cargo.map((x) => x.id).toString() +
                props.order.cargo.length
              }
              route={props.order.route}
              orderItems={props.order.cargo}
              hideSearch
              validationFailed={validationFailed}
              update={(cargo) => editCargo(cargo)}
            />
          </div>
          <div
            className="absolute bottom-0 pb-4 bg-white"
            style={{ width: "calc(100% - 24px)" }}
          >
            <div className={"flex bg-white gap-4 justify-start mt-4"}>
              <Button color="primary" onClick={() => handleUpdate()}>
                {t("orders:ongoing_orders.edit_cargo")}
              </Button>
              <Button color="gray" onClick={props.close}>
                {t("common:close")}
              </Button>
            </div>
          </div>
        </>
      }
    />
  );
};
