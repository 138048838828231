/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo, type FC } from "react";
import { PageLayout } from "../components";
import KpiStatuses from "../components/organisms/graphs/KpiStatuses";
import GreetingsWidget from "../components/molecules/Widgets/GreetingsWidget";
import ActivityLog from "../components/molecules/Cards/ActivityLog";
import { useWorkspace } from "../context/WorkspaceContext";
import { useOrders } from "../hooks";
import { Order } from "../models";
import { OrderStatus } from "../types/order/IOrder";
import { useTranslation } from "react-i18next";
import {
  emptyOnGoingOrdersIllustration,
  emptyCompletedOrdersIllustration,
} from "../components/atoms/Icons/illustrations";

const DashboardPage: FC = function () {
  const { t } = useTranslation(["stats"]);
  const { activeWorkspace } = useWorkspace();

  const query = useOrders(activeWorkspace?.workspaceId ?? "", []);

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  const orders = useMemo(() => query.data ?? [], [query]) as Order[];

  return (
    <PageLayout>
      <div className="grid lg:grid-cols-12 gap-4 ">
        <div className="col-span-8 lg:col-span-4">
          <GreetingsWidget />
        </div>
        <div className="col-span-8">
          <KpiStatuses orders={orders} isLoading={isQueryLoading} />
        </div>
      </div>

      <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <ActivityLog
          title={t("stats:orders.under_progress")}
          emptyTitle={t("stats:orders.empty_ongoing")}
          emptyIcon={emptyOnGoingOrdersIllustration}
          redirectPath="orders/ongoing"
          orders={orders.filter((x) => x.status === OrderStatus.InProgress)}
          isLoading={isQueryLoading}
        />
        <ActivityLog
          title={t("stats:orders.completed")}
          emptyTitle={t("stats:orders.empty_completed")}
          emptyIcon={emptyCompletedOrdersIllustration}
          redirectPath="orders/completed"
          orders={orders.filter((x) => x.status === OrderStatus.Completed)}
          isLoading={isQueryLoading}
        />
      </div>
    </PageLayout>
  );
};

export default DashboardPage;
