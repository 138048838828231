import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import backgroundImage from "../../../assets/widgetBackground.svg";
import Smiley from "../../../assets/widgetsmiley.svg";

interface FunMessages {
  [key: string]: string;
}

interface MonthNames {
  [key: string]: string;
}

const GreetingsWidget: FC<{ username?: string }> = ({}) => {
  const [currentTime, setCurrentTime] = useState<string>("");
  const [currentDate, setCurrentDate] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation(["common"]);

  const funMessages: FunMessages = {
    sunday: t("common:fun_messages.sunday"),
    monday: t("common:fun_messages.monday"),
    tuesday: t("common:fun_messages.tuesday"),
    wednesday: t("common:fun_messages.wednesday"),
    thursday: t("common:fun_messages.thursday"),
    friday: t("common:fun_messages.friday"),
    saturday: t("common:fun_messages.saturday"),
  };

  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const monthNames: MonthNames = {
    january: t("common:month_names.january"),
    february: t("common:month_names.february"),
    march: t("common:month_names.march"),
    april: t("common:month_names.april"),
    may: t("common:month_names.may"),
    june: t("common:month_names.june"),
    july: t("common:month_names.july"),
    august: t("common:month_names.august"),
    september: t("common:month_names.september"),
    october: t("common:month_names.october"),
    november: t("common:month_names.november"),
    december: t("common:month_names.december"),
  };

  const dayOfWeekIndex = new Date().getDay();
  const dayOfWeek = daysOfWeek[dayOfWeekIndex];
  const funMessage = funMessages[dayOfWeek];

  const monthIndex = new Date().getMonth();
  const monthName = months[monthIndex];
  const translatedMonthName = monthNames[monthName];

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      // Norwegian 24-hour time format and date format (dd.MM.yyyy)
      const timeString = now.toLocaleTimeString("nb-NO", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Ensure 24-hour format
      });

      const dateString = now.toLocaleDateString("nb-NO", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      setCurrentTime(timeString);
      setCurrentDate(dateString);
      setIsLoading(false); // Set loading to false once time and date are loaded
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div
      className=" min-h-[220px] w-full h-full flex flex-col justify-center gap-8 items-center text-white p-6 rounded-lg"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {isLoading ? (
        // Render spinner while loading
        <BeatLoader color="white" />
      ) : (
        // Render content once time and date are loaded
        <>
          <div className="flex flex-col w-full justify-between gap-0">
            <div className="flex-wrap-reverse gap-4 text-left flex flex-row w-full justify-between items-center ">
              <h1 className="text-2xl  font-semibold">
                {t("common:welcome")}!
              </h1>
              <img className="h-10 w-10" src={Smiley} alt="Smiley" />
            </div>

            <div className="flex w-full">
              <p className="text-md">{funMessage}</p>
            </div>
          </div>
          <div className="flex flex-row w-full text-lg justify-between items-center font-semibold md:text-xl lg:text-2xl flex-wrap ">
            <p className="mb-2 text-xl font-medium">
              {(() => {
                const [day, month] = currentDate.split(".");
                return `${parseInt(day)}. ${translatedMonthName}`;
              })()}
            </p>

            <p className="p-2 bg-white bg-opacity-20 rounded-lg text-white w-32 text-center text-xl font-medium">
              {currentTime}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default GreetingsWidget;
