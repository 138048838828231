/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import { Button } from "flowbite-react";
import {
  PageLayout,
  TableSkeleton,
  StandardTable,
  DialogForm,
  CustomerModal,
} from "../components";
import { HiOutlinePlus, HiTrash, HiPencilAlt } from "react-icons/hi";
import { useWorkspace } from "../context/WorkspaceContext";
import { useCache } from "../context/CacheContext";
import { useCustomers } from "../hooks";
import { useTranslation } from "react-i18next";
import { ICustomer, ITableRow } from "../types";
import { Customer } from "../models";
import { EmptyState } from "../components/molecules/EmptyState";
import { emptyCustomerIllustration } from "../components/atoms/Icons/illustrations";

const CustomersPage: FC = function () {
  const [isUpsertModalOpen, openUpsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [customerData, setCustomerData] = useState(Customer.default());
  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const query = useCustomers(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "customer"]);

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  const customers = useMemo(() => query.data ?? [], [query]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        customers.map((customer) => {
          return {
            id: customer.id,
            cells: [
              {
                id: "name",
                children: Customer.getCustomerName(customer),
                showOnSmallScreen: true,
              },
              {
                id: "email",
                children: customer.contactEmail,
              },
              {
                id: "isCompany",
                children: (
                  <div className="flex items-center">
                    {customer.isCompany ? (
                      <div className="mr-2 h-2.5 w-2.5 rounded-full bg-green-400"></div>
                    ) : (
                      <div className="mr-2 h-2.5 w-2.5 rounded-full bg-red-400"></div>
                    )}
                    {customer.isCompany ? t("common:yes") : t("common:no")}
                  </div>
                ),
              },
              {
                id: "organizationNumber",
                children: customer.organizationNumber,
              },
              {
                id: "phone",
                children: customer.phoneNumber,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setCustomerData(customer);
                  openUpsertModal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setCustomerData(customer);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, customers, t]);

  return (
    <PageLayout>
      <div className="relative pb-20">
        {tableRows.length > 0 ? ( // Check if there are any table rows
          <div className="flex flex-col sm:flex-row justify-between items-start p-4">
            <div>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                {t("customer:title")}
              </h1>
              <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
                {t("customer:description")}
              </p>
            </div>
            <Button
              color="light"
              onClick={() => {
                setCustomerData(Customer.default());
                openUpsertModal(true);
              }}
              type="submit"
              className="mb-4 sm:mb-0" // Bottom margin on small screens, no margin on larger screens
            >
              <HiOutlinePlus className="mr-2 h-5 w-5" />
              {t("customer:new_customer")}
            </Button>
          </div>
        ) : null}{" "}
        {/* Render nothing if there are no rows */}
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow text:white">
              {isLoading ? (
                <TableSkeleton />
              ) : tableRows.length === 0 ? (
                // Render empty state when there are no table rows
                <EmptyState
                  icon={emptyCustomerIllustration}
                  title={t("customer:empty_state.title")}
                  description={t("customer:empty_state.description")}
                  buttonText={t("customer:empty_state.button_text")}
                  buttonClick={() => {
                    setCustomerData(Customer.default());
                    openUpsertModal(true);
                  }}
                />
              ) : (
                <StandardTable
                  header={[
                    {
                      id: "name",
                      children: t("common:name"),
                      showOnSmallScreen: true,
                    },
                    { id: "email", children: t("common:email") },
                    { id: "isCompany", children: t("common:isCompany") },
                    {
                      id: "organizationNumber",
                      children: t("common:organizationNumber"),
                    },
                    { id: "phone", children: t("common:phoneNumber") },
                  ]}
                  hasActions={true}
                  rows={tableRows}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <CustomerModal
        data={customerData}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: ICustomer) => {
          if (Customer.isNew(customerData))
            Customer.create(activeWorkspace?.workspaceId ?? "", formData);
          else Customer.update(customerData, formData);
          openUpsertModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        onCancel={() => openUpsertModal(false)}
      />

      <DialogForm
        title={
          t("customer:delete_customer") +
          " " +
          (customerData.isCompany
            ? customerData.name
            : customerData.firstName + " " + customerData.lastName) +
          "?"
        }
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        buttonConfirmColor="failure"
        confirmButton={() => {
          Customer.delete(customerData);
          openDeleteModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />
    </PageLayout>
  );
};

export default CustomersPage;
