import { Timestamp } from "./Timestamp";
import { IProduct } from "./Product";

export interface IOrderProductItinerary {
  pickupStopNumber: number;
  deliveryStopNumber: number;
}

export enum IProductStatus {
  New = "New",
  Scheduled = "Scheduled",
  PickedUp = "PickedUp",
  Delivered = "Delivered",
  InTransit = "InTransit",
  Cancelled = "Cancelled",

  //Do we need these two?
  // Returned = "Returned",
  // Damaged = "Damaged", // damaged during transport
}

export interface IOrderProduct extends IProduct {
  deliveredAt: Timestamp | null;
  deliveredQuantity: number;
  pickedUpAt: Timestamp | null;
  pickedUpQuantity: number;
  price: number | null;
  lastUpdated: Timestamp | null;
  itinerary?: IOrderProductItinerary;
  productId?: string;
  status: IProductStatus;
  quantity: number;
}
