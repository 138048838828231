import { FC, ReactNode } from "react";

interface InfoCardProps {
  icon?: ReactNode; // Accepts any React node
  title?: string | number;
  titleClass?: string;
  description?: string;
  descriptionClass?: string;
  containerClass?: string;
  children?: React.ReactNode;
}

const InfoCard: FC<InfoCardProps> = (props) => {
  return (
    <div className={props.containerClass + " rounded-lg p-4 w-full"}>
      <div className="flex items-center h-full flex-wrap">
        {props.icon && <div className="mr-2">{props.icon}</div>}
        <p className={props.titleClass + " font-bold"}>{props.title}</p>
      </div>
      <p className={props.descriptionClass}>{props.description}</p>
      <div className={props.children ? "pt-4" : ""}>{props.children}</div>
    </div>
  );
};

export default InfoCard;
