/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "flowbite-react";
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
import { HiChevronLeft } from "react-icons/hi";
import { ITableCell, ITableRow } from "../../../types";
import { StandardTable } from "../Table";
import { TableSkeleton } from "../../atoms/Skeleton";
import { ButtonSearch } from "../..";
import { useTranslation } from "react-i18next";

export interface SplitTableProps {
  tableRows: ITableRow[];
  tableHeaders: ITableCell[];
  isLoading?: boolean;
  topRightContent?: React.ReactNode;
  contentOverTable?: React.ReactNode;
  content?: React.ReactNode;
  searchable?: boolean;
  forceOpen?: boolean;
  tableWidth?: string;
  contentWidth?: string;
  hasActions?: boolean;
  updateTable?: (searchText: string) => void;
  onRowClick: (item: any) => void;
}

export interface SplitTableRef {
  setSplit: (val: boolean) => void;
}
export const SplitTable = forwardRef(
  (props: SplitTableProps, ref: ForwardedRef<SplitTableRef>) => {
    const { t } = useTranslation(["common"]);
    const [split, setSplit] = useState(props.forceOpen ? true : false);

    /**
     * Exposed to the parent so it can be called through ref.
     */
    useImperativeHandle(ref, () => ({
      setSplit,
    }));

    const transition = "lg:transition-all lg:duration-200 lg:ease-in";

    const handleRowClick = (item: any) => {
      if (split && props.forceOpen !== true) {
        // If split is already open, close it
        setSplit(false);
        props.onRowClick(undefined);
      } else {
        // Open split view and pass the selected item
        setSplit(true);
        props.onRowClick(item);
      }
    };

    const closeSplit = () => {
      if (!props.forceOpen) {
        props.onRowClick(undefined);
        setSplit(false);
      }
    };

    return (
      <div className="flex">
        {/* Main Table Section */}
        <div
          className={`${split ? `${props.tableWidth ? props.tableWidth : "w-2/5"} overflow-hidden hidden lg:block` : "w-full overflow-x-scroll"} ${transition}`}
        >
          {props.searchable && (
            <ButtonSearch
              placeholder={t("common:search")}
              buttonText={t("common:search")}
              buttonClick={(input) =>
                props.updateTable ? props.updateTable(input) : null
              }
            />
          )}
          {props.contentOverTable && (
            <div className="w-full">{props.contentOverTable}</div>
          )}
          {props.isLoading ? (
            <TableSkeleton />
          ) : (
            <StandardTable
              onRowClick={(item) => handleRowClick(item)}
              header={props.tableHeaders}
              rows={props.tableRows.map((row) => ({
                ...row,
                className: "cursor-pointer", // Add cursor-pointer here
              }))}
              hasActions={props.hasActions}
            />
          )}
        </div>
        <div
          className={`${
            split
              ? `w-full lg:${props.contentWidth ? props.contentWidth : "w-3/5"} lg:ml-4 lg:border-l lg:dark:border-l-gray-700`
              : "w-0 h-0 overflow-hidden"
          } ${transition}`}
        >
          <div className="lg:pl-4">
            {!props.forceOpen && (
              <div className="flex justify-between items-center mb-4">
                <Button onClick={() => closeSplit()} size="sm" color="gray">
                  <HiChevronLeft size={18} />
                </Button>
                {props.topRightContent}
              </div>
            )}
            {props.content}
          </div>
        </div>
      </div>
    );
  },
);
