import type { FC } from "react";
import { StyledSelect } from ".";
import { countries } from "countries-list"; 
import { InputValidationResult } from "../../../types";

interface SelectCountryProps {
    id: string;
    defaultValue?: string;
    validation?: InputValidationResult;
    onSelectChanged?: (input: string) => void;
    disabled?: boolean;
  }

export const SelectCountry: FC<SelectCountryProps> = function(props: SelectCountryProps) {
  return (
    <StyledSelect 
        id={props.id}
        defaultValue={props.defaultValue}
        options={
            Object.keys(countries).map((code) => ({ key: code, label: countries[code as keyof typeof countries].name }))
        }
        validation={props.validation}
        onSelectChanged={props.onSelectChanged}
        disabled={props.disabled}
    />
  );
};

export default SelectCountry;