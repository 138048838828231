/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState } from "react";
import { Textarea, TextInput } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { StandardForm, Dialog } from "../..";
import { useTranslation } from "react-i18next";
import { Location } from "../../../models";

export interface LocationModalProp {
  data: Location;
  isShowing: boolean;
  onConfirm: (data: Location) => void;
  onCancel: () => void;
}

export const LocationForm: FC<LocationModalProp> = function (
  props: LocationModalProp,
) {
  const { t } = useTranslation(["common", "location", "modals"]);

  const [isNew] = useState(Location.isNew(props.data));

  return (
    <StandardForm
      showHeader={false}
      description={t("modals:descriptions.new_location")}
      fields={[
        {
          id: "displayName",
          label: t("location:modal.display_name"),
          input: (
            <TextInput
              id="displayName"
              name="displayName"
              placeholder={t("location:modal.display_name")}
              defaultValue={props.data.displayName ?? ""}
              type="text"
              required={true}
            />
          ),
        },
        {
          id: "addressLine",
          label: t("common:address_line"),
          input: (
            <TextInput
              id="addressLine"
              name="addressLine"
              placeholder={t("common:placeholder.address_line")}
              defaultValue={props.data.addressLine ?? ""}
            />
          ),
        },
        {
          id: "postCode",
          label: t("common:postcode"),
          input: (
            <TextInput
              id="postCode"
              name="postCode"
              placeholder={t("common:placeholder.postcode")}
              defaultValue={props.data.postCode ?? ""}
            />
          ),
        },
        {
          id: "city",
          label: t("common:city"),
          input: (
            <TextInput
              id="city"
              name="city"
              placeholder={t("common:placeholder.city")}
              defaultValue={props.data.city ?? ""}
            />
          ),
        },
        {
          id: "description",
          label: t("common:placeholder.description"),
          grid_style: "col-span-2",
          input: (
            <Textarea
              id="description"
              key="description"
              name="description"
              className="text-sm"
              placeholder={t("common:placeholder.description")}
              defaultValue={props.data.description ?? ""}
            />
          ),
        },
      ]}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      buttonConfirmText={t("modals:button_texts.location")}
      buttonConfirmPrependIcon={
        isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null
      }
      buttonCloseText={t("common:close")}
    />
  );
};

export const LocationModal: FC<LocationModalProp> = function (
  props: LocationModalProp,
) {
  const { t } = useTranslation(["location"]);

  return (
    <Dialog
      title={
        Location.isNew(props.data)
          ? t("location:modal.create_location")
          : t("location:modal.update_location")
      }
      content={<LocationForm {...props} />}
      show={props.isShowing}
      toggleModal={props.onCancel}
      hideButtons={true}
    />
  );
};
