import { type FC } from "react";
import { Button, Spinner } from "flowbite-react";
import { Dialog } from "../..//molecules/Dialog";

export interface DialogNotifyProp {
  show: boolean;
  isLoading: boolean;
  hideButtons?: boolean;
  buttonConfirmColor?: string;
  buttonConfirmText?: string;
  buttonConfirmPrependIcon?: React.ReactNode;
  buttonCloseText?: string;
  buttonClosePrependIcon?: React.ReactNode;
  icon?: React.ReactNode;
  iconBackground?: string;
  title: string;
  text?: string;
  size?: string;
  content?: React.ReactNode;
  toggleModal: () => void;
  confirmButton: (data?: any) => void;
}

export const DialogNotify: FC<DialogNotifyProp> = (props) => {
  const dialogContent = (
    <div className="flex flex-col items-center justify-between gap-4">
      {props.icon}
      <p className="text-xl font-medium text-gray-900">{props.title}</p>
      {props.text && (
        <p className="text-md text-lgb-grey-500 text-center">{props.text}</p>
      )}
      {props.content && <div>{props.content}</div>}
      <div className="flex gap-4">
        {props.buttonCloseText && (
          <Button color="gray" onClick={() => props.toggleModal()}>
            {props.buttonClosePrependIcon}
            {props.buttonCloseText}
          </Button>
        )}
        {
          !props.hideButtons &&
            props.buttonConfirmText &&
            (!props.isLoading ? (
              <Button
                onClick={() => props.confirmButton()}
                color={
                  props.buttonConfirmColor
                    ? props.buttonConfirmColor
                    : "primary"
                }
                type="submit"
              >
                {props.buttonConfirmPrependIcon}
                {props.buttonConfirmText}
              </Button>
            ) : (
              <Button
                color={
                  props.buttonConfirmColor
                    ? props.buttonConfirmColor
                    : "primary"
                }
              >
                <Spinner size={"sm"} />
              </Button>
            ))

          // <Button
          //   onClick={() => props.confirmButton()}
          //   color={
          //     props.buttonConfirmColor ? props.buttonConfirmColor : "primary"
          //   }
          //   type="submit"
          // >
          //   {props.buttonConfirmPrependIcon}
          //   {props.buttonConfirmText}
          // </Button>
        }
      </div>
    </div>
  );

  return (
    <Dialog
      size={props.size ?? "xl"}
      denseHeader
      content={dialogContent}
      show={props.show}
      toggleModal={props.toggleModal}
      hideButtons={true}
    />
  );
};
