import React, { FC, useState, useEffect } from "react";
import { Modal, Button } from "flowbite-react";

export interface ImageListProp {
  imgUrls: string[];
}

export const ImageList: FC<ImageListProp> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (imgUrl: string) => {
    setSelectedImage(imgUrl);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedImage(null);
    }
  }, [isOpen]);

  return (
    <div>
      <div className="flex gap-4 flex-wrap">
        {props.imgUrls.map((x, index) => (
          <div
            key={index}
            onClick={() => handleImageClick(x)}
            className="cursor-pointer"
          >
            <img
              width={56}
              height={56}
              src={x}
              alt={`Orderimage-${index}`}
              className="rounded-md object-fill h-14 w-14 cursor-pointer hover:ring-2 hover:ring-lgb-blue-400"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <Modal
          show={isOpen}
          size="lg"
          onClose={() => setIsOpen(false)}
          dismissible={true}
        >
          <Modal.Body className="flex justify-center items-center h-96">
            <img
              src={selectedImage}
              alt="Selected Orderimage"
              className="mx-auto rounded-lg"
              onError={() => {
                console.error("Image failed to load");
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="bg-lgb-blue-300"
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
