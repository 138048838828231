import type { FC } from "react";
import { OrderStatus } from "../../../types/order/IOrder";
import { useTranslation } from "react-i18next";

interface StatusBadgeProps {
  status: OrderStatus | string;
  bgStyle?: string;
  dot?: boolean;
  dotStyle?: string;
  rounded?: boolean;
}

export const StatusBadge: FC<StatusBadgeProps> = function (
  props: StatusBadgeProps,
) {
  const { t } = useTranslation("stats");

  const getStatusAndStyle = () => {
    switch (props.status) {
      case OrderStatus.Draft:
        return {
          text: t("orderStatus.draft"),
          bgStyle: "bg-lgb-grey-100 text-lgb-grey-600 ",
          dotStyle: "bg-lgb-grey-600 ",
        };
      case OrderStatus.New:
        return {
          text: t("orderStatus.scheduled"),
          bgStyle:
            "bg-lgb-yellow-100 dark:bg-lgb-yellow-200 text-lgb-yellow-600 ",
          dotStyle: "bg-lgb-yellow-600",
        };
      case OrderStatus.InProgress:
        return {
          text: t("orderStatus.in_progress"),
          bgStyle:
            "bg-lgb-blue-100 dark:bg-lgb-blue-200 text-lgb-blue-400 dark:text-lgb-blue-400 ",
          dotStyle: "bg-lgb-blue-400",
        };
      case OrderStatus.Cancelled:
        return {
          text: t("orderStatus.cancelled"),
          bgStyle: "bg-lgb-red-100 dark:bg-lgb-red-200 text-lgb-red-400 ",
          dotStyle: "bg-lgb-red-400",
        };
      case OrderStatus.Completed:
        return {
          text: t("orderStatus.completed"),
          bgStyle:
            "bg-lgb-green-100 dark:bg-lgb-green-200 text-lgb-green-400 dark:text-lgb-green-400 ",
          dotStyle: "bg-lgb-green-400",
        };
      default:
        return {
          text: props.status,
          bgStyle: props.bgStyle
            ? props.bgStyle + " "
            : "bg-lgb-grey-100 text-lgb-grey-600 ",
          dotStyle: props.dotStyle ? props.dotStyle : "bg-lgb-grey-600",
        };
    }
  };

  return (
    <div
      className={
        "w-max h-min flex items-center gap-2 text-xs font-medium px-2.5 py-1 " +
        getStatusAndStyle().bgStyle +
        (props.rounded ? "rounded-full" : "rounded-md")
      }
    >
      {props.dot && (
        <div
          className={"rounded " + getStatusAndStyle().dotStyle}
          style={{ width: "8px", height: "8px" }}
        ></div>
      )}
      <span>{getStatusAndStyle().text}</span>
    </div>
  );
};
