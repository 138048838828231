/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";

export interface LGBDatepickerProp {
  inputId?: string;
  selectedDate?: Date;
  showError?: boolean;
  select: (selectedDate: Date) => void;
}

export const LGBDatepicker: FC<LGBDatepickerProp> = (props) => {
  const { t } = useTranslation("common");
  const [value, setValue] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    setValue({
      startDate: props.selectedDate as Date,
      endDate: props.selectedDate as Date,
    });
  }, [props.selectedDate]);

  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue);
    switch (typeof newValue?.startDate) {
      case "string":
        props.select(new Date(newValue.startDate));
        break;
      case "object":
        props.select(newValue.startDate as Date);
        break;
    }
  };

  const maxDate = new Date();
  const minDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  const errorStyle =
    "border-lgb-red-200 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-lgb-red-100 dark:focus:border-red-500 dark:focus:ring-red-500";
  return (
    <div>
      <Datepicker
        inputClassName={
          (props.showError && !props.selectedDate ? errorStyle : "") +
          " border p-2.5  text-sm rounded-lg relative border-gray-300 dark:border-gray-700 dark:bg-lgb-dark-background dark:text-white w-full"
        }
        i18n={t("locale")}
        minDate={minDate}
        maxDate={maxDate}
        displayFormat={"DD.MM.YYYY"}
        asSingle
        startWeekOn="mon"
        useRange={false}
        value={value}
        onChange={(e) => handleValueChange(e)}
      />
      {props.showError && !props.selectedDate && (
        <span className="absolute left-0 -bottom-6 text-sm text-red-600 dark:text-red-500">
          {t("validation:route_info.date")}
        </span>
      )}
    </div>
  );
};
