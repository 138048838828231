import { ICustomer, Timestamp } from ".";

export interface IInvoice {
  id: string;
  sentDate?: Timestamp;
  paymentInfo?: IPaymentInfo;
  emissions?: IEmissions;
  invoiceEmail?: string;
  invoiceId?: number;
  relatedOrderId: number;
  customer: ICustomer;
  status: IInvoiceStatus;
  createdAt?: Timestamp | null;
  items: IInvoiceItem[];
}

export interface IInvoiceItem {
  itemTitle: string;
  quantity: number;
  price: number;
  discount?: number;
  vat?: number;
}

export interface IPaymentInfo {
  kid?: string;
  accountNumber?: string;
  dueDate?: Timestamp;
  KID?: string;
}

export interface IEmissions {
  emissionsInKg?: number;
}

export enum IInvoiceStatus {
  Draft = "Draft",
  Sent = "Sent",
}
