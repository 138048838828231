import { FC, useMemo } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import MapPin from "../../../assets/icons/MapPin.svg";

interface StaticMapProps {
  coordinates: { lat: number; lng: number };
  height: string;
}
const libraries: "marker"[] = ["marker"];

export const StaticMap: FC<StaticMapProps> = ({ coordinates, height }) => {
  const containerStyle = {
    width: "100%",
    height,
  };

  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: libraries,
  });

  const waypointPin = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MapPin,
      scaledSize: new window.google.maps.Size(28, 28),
      anchor: new window.google.maps.Point(14, 14),
    };
  }, [isLoaded]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div style={containerStyle}>Loading...</div>;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates}
      zoom={13}
      options={{
        disableDefaultUI: true,
        gestureHandling: "none",
      }}
    >
      <>
        <Marker position={coordinates} icon={waypointPin} />
      </>
    </GoogleMap>
  );
};

export default StaticMap;
