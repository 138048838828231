import { FC } from "react";
import { IMapRoute } from "../../../pages/orders/DriverMap";
import DropdownButton from "../../atoms/Button/DropdownButton";
import { useTranslation } from "react-i18next";
import { HiOutlineChevronDown, HiOutlineX } from "react-icons/hi";
import { IRouteItem } from "../../../types";
import { Avatar, Button } from "flowbite-react";

interface MapStopCardProps {
  stopInfo: IRouteItem;
  routeInfo: IMapRoute;
  close: () => void;
}

const MapStopCard: FC<MapStopCardProps> = function ({
  stopInfo,
  routeInfo,
  close,
}) {
  const { t } = useTranslation(["common", "orders"]);
  return (
    <div
      style={{ minWidth: "250px" }}
      className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <div className="flex justify-between px-4 pt-4">
        <Button onClick={close} size="xs" color="light" type="submit">
          {t("common:close")}
          <HiOutlineX className="ml-2" />
        </Button>
        <DropdownButton
          placement="bottom-end"
          buttonText=""
          buttonSize="xs"
          buttonAppendIcon={<HiOutlineChevronDown />}
          items={[
            {
              text: (
                <p className="p-4 w-48"> {t("orders:map_actions.edit_stop")}</p>
              ),
              onClick: () => {
                console.log(true);
              },
            },
            {
              text: (
                <p className="p-4 w-48">
                  {" "}
                  {t("orders:map_actions.edit_cargo")}
                </p>
              ),
              onClick: () => {
                console.log(true);
              },
            },
            {
              text: (
                <p className="p-4 w-48">
                  {" "}
                  {t("orders:map_actions.edit_customer")}
                </p>
              ),
              onClick: () => {
                console.log(true);
              },
            },
            {
              text: (
                <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                  {t("orders:map_actions.remove_stop")}
                </p>
              ),
              onClick: () => {
                console.log(true);
              },
            },
          ]}
        />
      </div>
      <div className="flex flex-col items-center pb-4">
        <Avatar
          img={""}
          placeholderInitials={
            routeInfo.customerName.includes(" ")
              ? routeInfo.customerName.split(" ")[0][0] +
                routeInfo.customerName.split(" ")[1][0]
              : routeInfo.customerName[0] + routeInfo.customerName[1]
          }
          size={"lg"}
          rounded
        />
        <h5 className="my-1 text-xl font-medium text-gray-900 dark:text-white">
          {stopInfo?.location.addressLine ?? ""}
        </h5>
        <span className="text-sm text-gray-500 dark:text-gray-400">
          {stopInfo.location.postCode ?? ""} {stopInfo.location.city ?? ""}
        </span>
        <span className="mt-2 text-sm dark:text-gray-400">
          {stopInfo.location.description ?? ""}
        </span>
      </div>
    </div>
  );
};

export default MapStopCard;
