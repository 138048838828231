/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useEffect, useMemo } from "react";
import { PageLayout } from "../../components";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useWorkspaces } from "../../hooks";
import { TableSkeleton, StandardTable } from "../../components";
import { useTranslation } from "react-i18next";
import { ITableRow, IWorkspaceAccess, WorkspaceRole, Timestamp } from "../../types";

export const WorkspacePage: FC = function () {
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const { t } = useTranslation(["common", "user", "workspace"]);
  const { activeWorkspace, setActiveWorkspace } = useWorkspace();
  const query = useWorkspaces();

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  const queryData = useMemo(() => query.data ?? [], [query]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        queryData.map((workspace) => {
          return {
            id: workspace.id,
            isActive: workspace.id === activeWorkspace?.workspaceId,
            cells: [
              {
                id: "name",
                children: workspace.name,
                showOnSmallScreen: true,
              },
              {
                id: "owner",
                children: workspace.owner,
              },
              {
                id: "createdAt",
                children: Timestamp.toDisplayDate(workspace.createdAt),
              },
            ],
            onRowClickData: workspace,
          };
        }),
      );
    }
  }, [isQueryLoading, queryData, t, activeWorkspace]);

  return (
    <PageLayout>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="pb-4">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                {t("workspace:admin_title")}
              </h1>
              <p className="text-lgb-grey-600 text-base pt-2 pb-4">
                {t("workspace:admin_description")}
              </p>
            </div>
            <div className="overflow-hidden shadow">
              {isLoading ? (
                <TableSkeleton />
              ) : (
                <StandardTable
                  header={[
                    {
                      id: "name",
                      children: t("workspace:workspaces.name"),
                      showOnSmallScreen: true,
                    },
                    {
                      id: "owner",
                      children: t("workspace:workspaces.owner"),
                    },
                    {
                      id: "createdAt",
                      children: t("common:created_at"),
                    }
                  ]}
                  rows={tableRows}
                  canMultiSelect={false}
                  onRowClick={(workspace) => {
                    const workspaceAccess: IWorkspaceAccess = {
                      workspaceId: workspace.id,
                      workspaceName: workspace.name,
                      email: workspace.owner,
                      role: WorkspaceRole.Owner,
                      createdBy: workspace.createdBy,
                      createdAt: workspace.createdAt,
                    };
                    setActiveWorkspace(workspaceAccess);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
