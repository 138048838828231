/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, CustomFlowbiteTheme, TextInput } from "flowbite-react";
import { ChangeEvent, type FC, useState } from "react";
import { HiSearch } from "react-icons/hi";

export interface ButtonSearchProp {
  placeholder: string;
  initialValue?: string;
  buttonText?: string;
  buttonClick: (input: string) => void;
}

const selectedTheme: CustomFlowbiteTheme = {
  textInput: {
    field: {
      input: {
        colors: {
          primary:
            "!rounded-none !rounded-l-lg !border-r-none font-semibold dark:text-white dark:bg-lgb-dark-background dark:text-white w-full",
        },
      },
    },
  },
};

export const ButtonSearch: FC<ButtonSearchProp> = (props) => {
  const [value, setValue] = useState(props.initialValue || "");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleButtonClick = () => {
    props.buttonClick(value || "");
  };

  const handleEnterClick = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      props.buttonClick(value || "");
    }
  };

  return (
    <div className="flex items-center align-center pb-4 ">
      <div className="grow">
        <TextInput
          theme={selectedTheme.textInput}
          color="primary"
          autoComplete="off"
          value={value || ""}
          icon={HiSearch}
          id="search"
          name="search"
          placeholder={props.placeholder}
          required
          size={32}
          type="search"
          onChange={(e) => {
            handleInputChange(e);
          }}
          onKeyDown={(e) => handleEnterClick(e)}
        />
      </div>
      {props.buttonText && (
        <div className={"whitespace-nowrap flex items-center justify-start"}>
          <Button
            className="bg-black dark:bg-gray-700 !rounded-none !rounded-r-lg"
            color="dark"
            onClick={() => handleButtonClick()}
            type="submit"
          >
            {props.buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};
