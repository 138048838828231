function handleNestedField(data: any, fieldId: any, fieldData: any) {
  const nestedFields = fieldId.split(".");
  // If one level, we save the data as is
  if (nestedFields.length === 1) {
    return setDataField(data, fieldId, fieldData);
  }
  // Extract child data element from the data field if it exists
  var childData = {};
  if (data.hasOwnProperty(nestedFields[0])) {
    childData = data[nestedFields[0]];
  }
  // Enrich child data
  childData = setDataField(
    childData,
    nestedFields.slice(1).join("."),
    fieldData,
  );
  return { ...data, [nestedFields[0]]: childData };
}

function setDataField(data: any, fieldId: any, fieldData: any) {
  return { ...data, [fieldId]: fieldData };
}

export { handleNestedField, setDataField };
