/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, FC, PropsWithRef } from "react";
import { Button, Card, Label, TextInput } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { ValidationError } from "../../../types/ValidationError";
import secondaryLogo from "../../../assets/logo/logibud_secondary_white.svg";
import loginBackground from "../../../assets/logibud_brand_background.svg";

interface PasswordRestFormProps {
  onClickPasswordReset: (email: string) => any;
  validation: ValidationError;
  isProcessing?: boolean | false;
}

const PasswordResetPage: FC<PropsWithRef<PasswordRestFormProps>> = function ({
  onClickPasswordReset,
  validation,
  isProcessing,
}) {
  const [email, setEmail] = useState("");

  const { t } = useTranslation("user");

  function handlPasswordReset(event: any) {
    event.preventDefault();
    return onClickPasswordReset(email);
  }

  return (
    <div
      style={{
        backgroundImage: `url(${loginBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        padding: "0",
      }}
      className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12"
    >
      <div className="my-6 flex items-center gap-x-1 lg:my-0">
        <img alt="Logibud" src={secondaryLogo} className="mr-3 h-12" />
      </div>
      <Card
        style={{
          background:
            "linear-gradient(135deg, rgba(6,11,39,1) 0%, #0c164d 100%)",
          border: "1px solid #42197f",
          maxWidth: "640px",
          boxShadow: "10px -9px 72px -9px rgba(114,20,255,0.42)",
        }}
        horizontal
        imgSrc=""
        imgAlt=""
        className="w-full md:max-w-[1024px] [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full md:[&>*]:p-16 lg:[&>img]:block"
      >
        <h1 className="mb-3 text-2xl font-bold text-white md:text-3xl">
          {t("login.reset_password_title")}
        </h1>
        <form onSubmit={handlPasswordReset}>
          <div className="mb-4 flex flex-col gap-y-3">
            <Label className="text-white" htmlFor="email">
              {t("login.email.label")}
            </Label>
            <TextInput
              id="email"
              name="email"
              placeholder={t("login.email.placeholder")}
              style={{ fontSize: 20 }}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              color={
                validation.errorFields &&
                validation.errorFields.includes("email")
                  ? "failure"
                  : "grey"
              }
            />
            {validation.isError && (
              <p className="mt-1 text-sm text-red-600 dark:text-red-500">
                {validation.errorMessage}
              </p>
            )}
          </div>
          <div className="mb-4">
            <Button
              type="submit"
              className="hover:bg-opacity-90 w-full h-14 bg-lgb-primary lg:w-full lg:max-w-full text-lg rounded-full flex justify-center items-center mt-2"
              onClick={() => onClickPasswordReset(email)}
              isProcessing={isProcessing}
            >
              {t("login.reset_password")}
            </Button>
          </div>
          <a
            href="/login"
            className="w-1/2 text-right text-lg text-lgb-grey-200 mt-4"
          >
            {t("login.back_to_login")}
          </a>
        </form>
      </Card>
    </div>
  );
};

export default PasswordResetPage;
