import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import {
  Timestamp,
  IOrder,
  IContactPerson,
  IRouteItem,
  ICustomer,
  IOrderProduct,
  IInvoice,
} from "../types";
import { query, doc } from "firebase/firestore";
import { Customer } from ".";
import { OrderStatus } from "../types/order/IOrder";
import { IOrderValidated } from "../types/order";
import { CreateOrderStepsEnum } from "../pages/orders/create-order";
import {
  validateMobile,
  validateStringLength,
} from "../helpers/validationHelper";
import {
  IEmissions,
  IInvoiceItem,
  IInvoiceStatus,
  IPaymentInfo,
} from "../types/Invoice";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Invoice implements IInvoice {
  id: string;
  sentDate?: Timestamp;
  paymentInfo?: IPaymentInfo;
  emissions?: IEmissions;
  invoiceEmail?: string;
  invoiceId?: number;
  relatedOrderId: number;
  customer: ICustomer;
  status: IInvoiceStatus;
  items: IInvoiceItem[];
  createdAt?: Timestamp | null;

  static collectionName = "orders";

  static converter = {
    toFirestore(order: Invoice) {
      return order.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      let data = snapshot.data(options) as IInvoice;
      return new Invoice({
        ...data,
        id: snapshot.id,
      });
    },
  };

  static createId() {
    return firestore.collection(Invoice.collectionName).doc().id;
  }

  constructor({
    id,
    sentDate,
    paymentInfo,
    emissions,
    invoiceEmail,
    invoiceId,
    relatedOrderId,
    customer,
    status,
    items,
    createdAt,
  }: IInvoice) {
    this.id = id;
    this.sentDate = sentDate;
    this.paymentInfo = paymentInfo;
    this.emissions = emissions;
    this.invoiceEmail = invoiceEmail;
    this.invoiceId = invoiceId;
    this.relatedOrderId = relatedOrderId;
    this.customer = customer;
    this.status = status || IInvoiceStatus.Draft;
    this.items = items;
    this.createdAt = createdAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IInvoice>) {
    return Object.assign(this, updates);
  }
  toPlainObj(): Invoice {
    return Object.assign({}, this);
  }

  data() {
    return {
      id: this.id,
      sentDate: this.sentDate,
      paymentInfo: this.paymentInfo,
      emissions: this.emissions,
      invoiceEmail: this.invoiceEmail,
      invoiceId: this.invoiceId,
      relatedOrderId: this.relatedOrderId,
      customer: this.customer,
      status: this.status,
      createdAt: this.createdAt,
    };
  }

  static default = (): IInvoice => {
    return {
      id: "",
      sentDate: undefined,
      paymentInfo: undefined,
      emissions: undefined,
      invoiceEmail: "",
      invoiceId: undefined,
      relatedOrderId: 0,
      customer: Customer.default(),
      status: IInvoiceStatus.Draft,
      items: [],
      createdAt: null,
    };
  };

  static get = (id: string) => {
    return doc(
      firestore
        .collection(Invoice.collectionName)
        .withConverter(Invoice.converter),
      id,
    );
  };

  static list = (workspaceId: string, statuses: OrderStatus[] = []) => {
    let request = firestore
      .collection(Invoice.collectionName)
      .where("workspaceId", "==", workspaceId);

    if (statuses.length > 0) {
      request = request.where("status", "in", statuses);
    }

    return query(request.withConverter(Invoice.converter));
  };

  static validateCustomer = (customer: ICustomer) => {
    return customer && customer.id !== "";
  };
  static validateRoute = (routes: IRouteItem[]) => {
    let valid = true;
    for (const route of routes) {
      if (
        !(
          route.stopDate !== undefined &&
          route.location &&
          validateStringLength(route.location.addressLine ?? "", 2) &&
          route.location.postCode?.length === 4 &&
          validateStringLength(route.location.city, 2)
        )
      ) {
        valid = false;
      }
    }
    return valid;
  };
  static validateCargo = (cargo: IOrderProduct[]) => {
    let valid = true;
    for (const item of cargo) {
      if (
        !(
          item.itinerary !== undefined &&
          item.itinerary?.pickupStopNumber <
            item.itinerary?.deliveryStopNumber &&
          item.quantity > 0 &&
          item.descriptionShort.length &&
          item.height > 0 &&
          item.weight > 0 &&
          item.length > 0 &&
          item.weight > 0 &&
          item.pricing &&
          item.pricing.priceModel &&
          item.pricing.priceModel.length > 0 &&
          item.productType
        )
      ) {
        valid = false;
      }
    }
    return cargo.length ? valid : false;
  };
  static validateContacts = (contacts: IContactPerson[]) => {
    let valid = true;
    for (const person of contacts) {
      if (
        !(
          person.location &&
          validateStringLength(person.name, 3) &&
          validateMobile(person.phoneNumber)
        )
      ) {
        valid = false;
      }
    }
    return contacts.length ? valid : false;
  };

  static validateDriver = (driverId: string | undefined) => {
    return driverId !== undefined && driverId !== "";
  };

  static validateStep = (order: IOrder, step: number) => {
    let validation = false;
    switch (step) {
      case CreateOrderStepsEnum.CUSTOMER_SELECTION:
        validation = Invoice.validateCustomer(order.customer);
        break;
      case CreateOrderStepsEnum.ROUTE_SELECTION:
        validation = Invoice.validateRoute(order.route);
        break;
      case CreateOrderStepsEnum.PRODUCT_MANAGEMENT:
        validation = Invoice.validateCargo(order.cargo);
        break;
      case CreateOrderStepsEnum.DRIVER_SELECTION:
        validation = Invoice.validateDriver(order.driverId);
        break;
      default:
        validation = false;
        break;
    }
    return validation;
  };

  static validateOrder(order: IOrder) {
    let validationValues = {
      customer:
        order.customerId &&
        order.customerId !== "" &&
        order.customer &&
        order.customer.id !== "",
      route: order.route && order.route.length > 0,
      cargo: order.cargo && order.cargo.length > 0,
    } as IOrderValidated;

    let invalid = Object.entries(validationValues);
    return {
      isValidOrder: invalid.filter(([key, value]) => !value).length === 0,
      invalidFields: Object.fromEntries(invalid),
    };
  }

  static create = async (data: Omit<IInvoice, "id | createdAt">) => {
    data = structuredClone(data);
    const newInvoice = new Invoice({
      ...data,
      id: uuidv4().toString(),
      status: IInvoiceStatus.Draft,
      createdAt: Timestamp.now(),
    });
    try {
      await firestore
        .collection(Invoice.collectionName)
        .doc(newInvoice.id)
        .withConverter(Invoice.converter)
        .set(newInvoice, { merge: true });
    } catch (e) {
      console.warn("Create order failed with error: ", e);
      return false;
    }
    return true;
  };

  static delete = async (order: Invoice) => {
    return await firestore
      .collection(Invoice.collectionName)
      .doc(order.id)
      .delete();
  };
  static update = async (order: Invoice, updates: Partial<IInvoice>) => {
    order.setData({ ...updates });
    order = structuredClone(order);
    const updatedOrder = new Invoice({ ...order });
    try {
      await firestore
        .collection(Invoice.collectionName)
        .doc(order.id)
        .withConverter(Invoice.converter)
        .set(updatedOrder, { merge: true });
    } catch (e) {
      console.warn(e);
    }

    return updatedOrder;
  };
}

export default Invoice;
