/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../../components";
import { Driver, Order } from "../../../models";
import { Button } from "flowbite-react";
import { HiOutlinePlus, HiOutlineX } from "react-icons/hi";
import { DriverSelection } from "../../../pages/orders/create-order";
import { DriverSelectionRef } from "../../../pages/orders/create-order/steps/DriverSelection";

interface EditDriverProp {
  drivers: Driver[];
  show: boolean;
  order: Order;
  isLoading: boolean;
  type: "add" | "edit";
  edit: (driverId: string) => void;
  close: () => void;
}

export const EditDriver: FC<EditDriverProp> = function (props: EditDriverProp) {
  const { t } = useTranslation(["common", "orders"]);
  const driverSelectionRef = useRef<DriverSelectionRef>(null);

  function update() {
    if (driverSelectionRef.current) {
      driverSelectionRef.current.updateDriver();
    }
  }

  function editDriver(id: string) {
    props.edit(id);
    props.close();
  }

  return (
    <Dialog
      title={
        props.order.driverId
          ? t("orders:scheduled_orders.change_driver")
          : t("orders:scheduled_orders.add_driver_title")
      }
      show={props.show}
      toggleModal={() => props.close()}
      hideButtons
      content={
        <>
          <div className="text-lgb-grey-600 text-base pb-4 ">
            {t("orders:scheduled_orders.driver_description")}
          </div>
          <div className="pb-14">
            <DriverSelection
              ref={driverSelectionRef}
              order={props.order}
              update={(id) => editDriver(id)}
            ></DriverSelection>
          </div>
          <div
            className="absolute bottom-0 pb-6 bg-white rounded-lg"
            style={{ width: "calc(100% - 24px)" }}
          >
            <div className={"flex bg-white gap-4 justify-start mt-4"}>
              <Button color="primary" onClick={() => update()}>
                <HiOutlinePlus className="mr-2 h-5 w-5" />
                {props.order.driverId
                  ? t("orders:scheduled_orders.change_driver")
                  : t("orders:scheduled_orders.add_driver_title")}
              </Button>
              <Button color="gray" onClick={props.close}>
                <HiOutlineX className="mr-2 h-5 w-5" />
                {t("common:close")}
              </Button>
            </div>
          </div>
        </>
      }
    />
  );
};
