import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { Location } from "../models";
import { useCache } from "../context/CacheContext";

const useLocations = (workspaceId: string) => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData(
    [Location.collectionName + "_" + workspaceId, cacheKey],
    Location.list(workspaceId),
    {
      subscribe: false,
    },
  );
};

export default useLocations;
