/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { Avatar } from "flowbite-react";
import { HiCheck } from "react-icons/hi";

export interface DropdownSearchRadioItemProp {
  title: string;
  subtitle?: string;
  id: string;
  selected?: boolean;
  inlineSubtitle?: boolean;
  buttonClickEvent?: (id: string) => void;
}

export const DropdownSearchRadioItem: FC<DropdownSearchRadioItemProp> = (
  props,
) => {
  const handleButtonClick = () => {
    if (props.buttonClickEvent) {
      props.buttonClickEvent(props.id);
    }
  };

  return (
    <li
      className={
        "group border-dashed border dark:bg-lgb-on-dark-background dark:text-white hover:bg-lgb-blue-100 dark:hover:bg-lgb-blue-350 hover:border-lgb-primary border-gray-400 my-4 px-4 py-1 rounded-lg cursor-pointer" +
        (props.selected
          ? " bg-lgb-blue-100 dark:!bg-lgb-blue-350  border-lgb-blue-300 font-medium "
          : "")
      }
      onClick={handleButtonClick}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <Avatar
            img={""}
            placeholderInitials={
              props.title.includes(" ")
                ? props.title.split(" ")[0][0] + props.title.split(" ")[1][0]
                : props.title[0] + props.title[1]
            }
            size={"sm"}
            rounded
          />
          <p className={"text-sm"}>{props.title + " "}</p>
        </div>
        {props.selected && (
          <div
            className="bg-lgb-primary rounded-xl"
            style={{ width: "24px", height: "24px" }}
          >
            <HiCheck size={"24px"} className="text-white" />
          </div>
        )}
        {!props.selected && (
          <div
            className="rounded-xl border border-gray-400 group-hover:border-lgb-primary bg-white"
            style={{ width: "24px", height: "24px" }}
          ></div>
        )}
      </div>
    </li>
  );
};
