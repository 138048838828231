/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DownloadPDFButton, SplitTable, StatusBadge } from "../..";
import { Invoice } from "../../../models";
import { ITableRow } from "../../../types";
import { Button } from "flowbite-react";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import { HiOutlineChevronDown } from "react-icons/hi";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { IInvoiceStatus } from "../../../types/Invoice";
import { HiOutlineArrowRight } from "react-icons/hi";
import { InvoiceDetails } from "../../molecules/InvoiceDetails";
import { SendInvoice } from "./SendInvoice";
import { InvoicePDF } from "./InvoicePDF";
import PDFRenderer from "../PDFRenderer/PDFRenderer";

interface InvoiceListProp {
  invoices: Invoice[];
  isLoading: boolean;
  isOpen: (val: boolean) => void;
}

export const InvoiceList: FC<InvoiceListProp> = function (
  props: InvoiceListProp,
) {
  const { t } = useTranslation(["common", "orders", "validation"]);
  const [openedInvoice, setOpenedInvoice] = useState<Invoice | undefined>(
    undefined,
  );
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [hideColumns, setHideColumns] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewedInvoice, setPreviewedInvoice] = useState<
    Invoice | undefined
  >();
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Invoice[]>();
  useState(false);
  const [showSendInvoiceDialog, setShowSendInvoiceDialog] = useState(false);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState<
    string | undefined
  >(undefined);
  const splitTableRef = useRef<SplitTableRef>(null);

  const updateOpenedOrder = (invoice: Invoice | undefined) => {
    setOpenedInvoice(invoice);
    if (invoice !== undefined) {
      setHideColumns(true);
      props.isOpen(true);
    } else {
      setHideColumns(false);
      props.isOpen(false);
    }
  };
  const getTotalPrice = (invoice: Invoice) => {
    return invoice.items.reduce((accumulator, item) => {
      let itemTotal = item.quantity * item.price;

      // Apply discount if any
      if (item.discount) {
        itemTotal -= (itemTotal * item.discount) / 100;
      }

      // Apply VAT if any
      if (item.vat) {
        itemTotal += (itemTotal * item.vat) / 100;
      }

      return accumulator + itemTotal;
    }, 0);
  };

  useEffect(() => {
    if (!props.isLoading && props.invoices) {
      if (searchText.length) {
        setSearchResults([]); //TODO: Filter
      } else {
        setSearchResults(props.invoices || []);
      }
    }
  }, [searchText, props.invoices, props.isLoading]);

  useEffect(() => {
    if (!props.isLoading && searchResults) {
      setTableRows(
        searchResults.map((invoice) => {
          return {
            id: invoice.id,
            isActive: invoice.id === openedInvoice?.id,
            onRowClickData: invoice,
            cells: [
              {
                id: "customer",
                children: invoice.customer.isCompany
                  ? invoice.customer.name
                  : invoice.customer.firstName +
                    " " +
                    invoice.customer.lastName,
                showOnSmallScreen: true,
              },
              {
                id: "status",
                children:
                  invoice.status === IInvoiceStatus.Draft ? (
                    <StatusBadge
                      status={invoice.status}
                      bgStyle={
                        "bg-lgb-blue-100 dark:bg-lgb-blue-200 text-lgb-blue-400 dark:text-lgb-blue-400 "
                      }
                      dotStyle={"bg-lgb-blue-400"}
                    />
                  ) : (
                    <StatusBadge
                      status={invoice.status}
                      bgStyle={
                        "bg-lgb-green-100 dark:bg-lgb-green-200 text-lgb-green-400 dark:text-lgb-green-400 "
                      }
                      dotStyle={"bg-lgb-green-400"}
                    />
                  ),
                showOnSmallScreen: true,
              },
              {
                id: "sum_total",
                children:
                  "NOK " + getTotalPrice(invoice)?.toFixed(2).replace(".", ","),
                showOnSmallScreen: false,
                hidden: openedInvoice !== undefined,
              },
            ],
            actions: !openedInvoice
              ? [
                  ...(invoice.status === IInvoiceStatus.Draft
                    ? [
                        {
                          children: (
                            <div className="flex justify-end w-full ">
                              <Button
                                color={"green300"}
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  setCurrentCustomerEmail(
                                    invoice.invoiceEmail || undefined,
                                  );
                                  setShowSendInvoiceDialog(true);
                                }}
                              >
                                {t("common:send")}
                                <HiOutlineArrowRight className="ml-2 h-5 w-5" />
                              </Button>
                            </div>
                          ),
                        },
                      ]
                    : []),
                  ...(invoice.status === IInvoiceStatus.Sent
                    ? [
                        {
                          children: (
                            <div className="flex justify-end w-full">
                              <Button
                                color="light"
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  setPreviewedInvoice(invoice);
                                  setShowPreview(true);
                                }}
                              >
                                {t("invoice:invoice_preview")}
                              </Button>
                            </div>
                          ),
                        },
                      ]
                    : []),
                ]
              : [],
          };
        }),
      );
    }
  }, [props.isLoading, searchResults, t, openedInvoice, hideColumns]);
  return (
    <>
      <SplitTable
        ref={splitTableRef}
        searchable
        hasActions
        isLoading={props.isLoading}
        tableRows={tableRows}
        onRowClick={(order: Invoice | undefined) => updateOpenedOrder(order)}
        updateTable={(val) => setSearchText(val)}
        content={
          <InvoiceDetails
            invoice={openedInvoice}
            sendInvoice={() => {
              setCurrentCustomerEmail(openedInvoice?.invoiceEmail || undefined);
              setShowSendInvoiceDialog(true);
            }}
          />
        }
        topRightContent={
          <div className="flex items-center">
            <DownloadPDFButton
              pdf={<InvoicePDF invoice={openedInvoice} />}
              fileName={String(openedInvoice?.invoiceId)}
            />
            {openedInvoice && (
              <DropdownButton
                placement="bottom-end"
                buttonText={t("invoice:actions.buttonText")}
                buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
                items={[
                  ...(openedInvoice?.status === IInvoiceStatus.Draft
                    ? [
                        {
                          text: (
                            <p className="p-4 w-48">
                              {t("invoice:actions.edit_items.buttonText")}
                            </p>
                          ),
                          onClick: () => {
                            console.log(true);
                          },
                        },
                      ]
                    : []),
                  {
                    text: (
                      <p className="p-4 w-48">
                        {t("invoice:actions.settings.buttonText")}
                      </p>
                    ),
                    onClick: () => {
                      console.log(true);
                    },
                  },
                ]}
              />
            )}
          </div>
        }
        tableHeaders={[
          {
            id: "customer",
            children: t("common:customer"),
            showOnSmallScreen: true,
          },
          {
            id: "status",
            children: t("common:status"),
            showOnSmallScreen: true,
          },
          {
            id: "sum_total",
            children: t("invoice:pdf.sum_total"),
            showOnSmallScreen: false,
            hidden: openedInvoice !== undefined,
          },
        ]}
      />
      <SendInvoice
        show={showSendInvoiceDialog}
        isLoading={false}
        prefilledEmail={currentCustomerEmail}
        toggleModal={() => setShowSendInvoiceDialog(!showSendInvoiceDialog)}
        confirmButton={(email: string) => {
          console.log("Send to: ", email);
          setCurrentCustomerEmail(undefined);
          setShowSendInvoiceDialog(!showSendInvoiceDialog);
        }}
      />
      {showPreview && (
        <PDFRenderer
          content={<InvoicePDF invoice={previewedInvoice} />}
          close={() => {
            setPreviewedInvoice(undefined);
            setShowPreview(false);
          }}
        />
      )}
    </>
  );
};
