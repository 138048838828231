import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Label, TextInput } from "flowbite-react";
import { IContactPerson } from "../../../types";
import { HiOutlinePlus } from "react-icons/hi";
import {
  validateMobile,
  validateStringLength,
} from "../../../helpers/validationHelper";

export interface RouteCardProps {
  update: (contact: IContactPerson) => void;
  close: () => void;
}

export const RouteCard: FC<RouteCardProps> = (props) => {
  const [contactPerson, setContactPerson] = useState({} as IContactPerson);
  const [validated, setValidated] = useState({
    name: true,
    phoneNumber: true,
  });
  const { t } = useTranslation(["orders", "common"]);

  const updateContactPerson = (key: string, value: string) => {
    setContactPerson({ ...contactPerson, [key]: value });
  };

  const verifyAndAdd = () => {
    let nameValidated = validateStringLength(contactPerson.name, 3) || false;
    let phoneValidated = validateMobile(contactPerson.phoneNumber) || false;
    if (nameValidated && phoneValidated) {
      props.update(contactPerson);
      props.close();
    } else {
      setValidated({ name: nameValidated, phoneNumber: phoneValidated });
    }
  };

  return (
    <div>
      <div className="mb-6">
        <div className="mb-2 block">
          <Label htmlFor="name" value={t("create.contact_info.name")} />
        </div>
        <TextInput
          autoComplete="off"
          id="name"
          name="name"
          placeholder={t("create.contact_info.name")}
          required
          type="text"
          onChange={(e) => updateContactPerson("name", e.target.value)}
          color={
            !validated.name && !validateStringLength(contactPerson.name, 3)
              ? "failure"
              : undefined
          }
          helperText={
            !validated.name && !validateStringLength(contactPerson.name, 3) ? (
              <p className="absolute text-sm text-red-600 dark:text-red-500">
                {t("validation:minimum_length").replace("{1}", "3")}
              </p>
            ) : (
              ""
            )
          }
        />
      </div>

      <div className="mb-2">
        <div className="mb-2 block">
          <Label
            htmlFor="phone"
            value={t("create.contact_info.phone_number")}
          />
        </div>
        <TextInput
          autoComplete="off"
          id="phone"
          name="phone"
          placeholder={t("create.contact_info.phone_number")}
          required
          type="text"
          onChange={(e) => updateContactPerson("phoneNumber", e.target.value)}
          color={
            !validated.phoneNumber && !validateMobile(contactPerson.phoneNumber)
              ? "failure"
              : undefined
          }
          helperText={
            !validated.phoneNumber &&
            !validateMobile(contactPerson.phoneNumber) ? (
              <p className="absolute text-sm text-red-600 dark:text-red-500">
                {t("validation:mobile_number")}
              </p>
            ) : (
              ""
            )
          }
        />
      </div>
      <div className="pt-8 flex gap-4">
        <Button
          disabled={
            !(
              contactPerson &&
              contactPerson.name?.length &&
              contactPerson.phoneNumber?.length
            )
          }
          color="primary"
          onClick={() => verifyAndAdd()}
        >
          <HiOutlinePlus className="mr-2 h-5 w-5" />
          {t("create.contact_info.title")}
        </Button>
        <Button color="gray" type="submit" onClick={() => props.close()}>
          {t("common:close")}
        </Button>
      </div>
    </div>
  );
};

export default RouteCard;
