/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "../../../components";
import { Order } from "../../../models";
import { Button } from "flowbite-react";
import { RouteSelection } from "../../../pages/orders/create-order";
import { IRouteItem } from "../../../types";
import { RouteSelectionRef } from "../../../pages/orders/create-order/steps/RouteSelection";

interface EditRouteProp {
  route: IRouteItem[];
  show: boolean;
  edit: (route: IRouteItem[]) => void;
  close: () => void;
}

export const EditRoute: FC<EditRouteProp> = function (props: EditRouteProp) {
  const { t } = useTranslation(["common", "orders"]);
  const [validationFailed, setValidationFailed] = useState(false);
  const routeSelectionRef = useRef<RouteSelectionRef>(null);

  function editRoute(route: IRouteItem[]) {
    if (!Order.validateRoute(route)) {
      setValidationFailed(true);
    } else {
      setValidationFailed(false);
      props.edit(route);
      props.close();
    }
  }

  const handleUpdate = () => {
    if (routeSelectionRef.current) {
      routeSelectionRef.current.updateOrderRoute();
    }
  };
  return (
    <Dialog
      size="7xl"
      title={t("orders:ongoing_orders.edit_route")}
      show={props.show}
      toggleModal={() => props.close()}
      hideButtons
      content={
        <>
          <div className="text-lgb-grey-600 text-base pb-4 ">
            {t("orders:ongoing_orders.edit_route_description")}
          </div>
          <div className="pb-16">
            <RouteSelection
              ref={routeSelectionRef} //Used to access the child's update function
              route={props.route}
              hideSearch
              validationFailed={validationFailed}
              update={(route) => editRoute(route)}
            />
          </div>
          <div
            className="absolute bottom-0 pb-4 bg-white"
            style={{ width: "calc(100% - 24px)" }}
          >
            <div className={"flex bg-white gap-4 justify-start mt-4"}>
              <Button color="primary" onClick={() => handleUpdate()}>
                {t("orders:ongoing_orders.edit_route")}
              </Button>
              <Button color="gray" onClick={props.close}>
                {t("common:close")}
              </Button>
            </div>
          </div>
        </>
      }
    />
  );
};
