import React from "react";

interface NextUpListItemProps {
  icon: any;
  customerName: string;
  address: string;
  timeLabel: string;
  time: string;
  timeClassName?: string; // Adding the optional timeClassName prop
  timeLabelClassName?: string; // Adding the optional timeLabelClassName prop
  redirectPath: string;
}

const NextUpListItem: React.FC<NextUpListItemProps> = ({
  icon,
  customerName,
  address,
  timeLabel,
  time,
  timeClassName = "", // Default value is an empty string
  timeLabelClassName = "", // Default value is an empty string
  redirectPath = "",
}) => {
  return (
    <a
      href={redirectPath}
      className="flex items-center space-x-4  p-2 py-3 text-sm font-medium text-lgb-dark-background hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-primary-500  border-b border-gray-200 dark:border-gray-700"
    >
      <div className="shrink-0">{icon}</div>
      <div className="min-w-0 flex-1">
        <p className="truncate text-md font-medium text-lgb-darkBlue dark:text-white">
          {customerName}
        </p>
        <p className="truncate text-md text-gray-500 dark:text-gray-400 font-normal">
          {address}
        </p>
      </div>
      <div className="min-w-0 flex flex-col items-end">
        <p
          className={`truncate text-md text-gray-500 dark:text-white ${timeLabelClassName}`}
        >
          {timeLabel}
        </p>
        <p
          className={`truncate text-md font-medium text-lgb-darkBlue dark:text-gray-400 ${timeClassName}`}
        >
          {time}
        </p>
      </div>
    </a>
  );
};

export default NextUpListItem;
