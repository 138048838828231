import firebase from "firebase/compat/app";
import moment from "moment";

export class Timestamp extends firebase.firestore.Timestamp {
  static now = () => {
    return firebase.firestore.Timestamp.fromDate(new Date());
  };
  static fromMoment = (m: moment.Moment): firebase.firestore.Timestamp =>
    firebase.firestore.Timestamp.fromDate(m.toDate());

  static fromDate = (d: Date): firebase.firestore.Timestamp => {
    return firebase.firestore.Timestamp.fromDate(d);
  };

  static toMoment = (timestamp: firebase.firestore.Timestamp): moment.Moment =>
    moment(
      new firebase.firestore.Timestamp(
        timestamp.seconds,
        timestamp.nanoseconds,
      ).toDate(),
    );

  static toDisplayDate = (timestamp?: firebase.firestore.Timestamp): string => {
    if (timestamp === undefined) return "";
    return moment(
      new firebase.firestore.Timestamp(
        timestamp.seconds,
        timestamp.nanoseconds,
      ).toDate(),
    ).format("DD.MM.YYYY");
  };

  static ToDisplayTime = (timestamp?: firebase.firestore.Timestamp): string => {
    if (timestamp === undefined) return "";
    return moment(
      new firebase.firestore.Timestamp(
        timestamp.seconds,
        timestamp.nanoseconds,
      ).toDate(),
    ).format("HH:mm");
  };
}
