import { Button, CustomFlowbiteTheme, TextInput } from "flowbite-react";
import { ChangeEvent, useEffect, useRef, useState, type FC } from "react";
import { HiSearch, HiOutlinePlus } from "react-icons/hi";

export interface DropdownSearchProp {
  placeholder: string;
  value?: string; // Accept a value prop to control the input
  children?: React.ReactNode; // This is the list of search results.
  showResults?: boolean;
  childrenAbsolute?: boolean;
  plain?: boolean;
  buttonText?: string;
  inputChanged?: (input: string) => void;
  buttonClick?: () => void;
  clickOutside?: (show: boolean) => void;
}

const selectedTheme: CustomFlowbiteTheme = {
  textInput: {
    field: {
      input: {
        colors: {
          primary:
            "cursor-pointer dark:text-white dark:bg-lgb-dark-background w-full",
        },
      },
    },
  },
};

export const DropdownSearch: FC<DropdownSearchProp> = (props) => {
  const [value, setValue] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        props.clickOutside && props.clickOutside(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props, props.clickOutside]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    return props.inputChanged ? props.inputChanged(event.target.value) : null;
  };

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex gap-4 items-center align-center">
        <div className="grow">
          <TextInput
            theme={selectedTheme.textInput}
            color="primary"
            autoComplete="off"
            value={value || ""} // Use the value prop for the input field
            icon={HiSearch}
            id="search"
            name="search"
            placeholder={props.placeholder}
            required
            size={32}
            type="search"
            onChange={handleInputChange}
          />
        </div>
        {props.buttonText && (
          <div className={"whitespace-nowrap flex items-center justify-start"}>
            <Button
              className="bg-gray-500 dark:bg-gray-700"
              color="dark"
              onClick={props.buttonClick}
              type="submit"
            >
              <HiOutlinePlus className="mr-2 h-5 w-5" />
              {props.buttonText}
            </Button>
          </div>
        )}
      </div>

      {/* Render the search results */}
      {props.showResults && (
        <ul
          className={
            " mt-1 divide-y divide-gray-200 dark:divide-gray-700 z-10 dark:bg-lgb-dark-background dark:border-lgb-grey-600 dark:text-lgb-green-300  w-full" +
            (props.childrenAbsolute ? " bg-white absolute" : "") +
            (props.plain ? "" : " shadow-xl rounded-xl p-4")
          }
        >
          {props.children}
        </ul>
      )}
    </div>
  );
};
