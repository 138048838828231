/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, type FC } from "react";
import { IOrder } from "../../../types";
import { useTranslation } from "react-i18next";
import { Button, Spinner, Textarea } from "flowbite-react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { HiCheck } from "react-icons/hi";
import { Order } from "../../../models";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { useUserSession } from "../../../context/UserContext";
import { IOrderProduct } from "../../../types";
import { createOrder as createOrderApi } from "../../../api";

export interface OrderSummaryProp {
  order: IOrder;
  close: () => void;
}

export const OrderSummary: FC<OrderSummaryProp> = (props) => {
  const { t } = useTranslation(["orders"]);
  const [comment, setComment] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [create, setCreate] = useState(false);
  const [fullOrder, setFullOrder] = useState(props.order);

  const { activeWorkspace } = useWorkspace();
  const { userSession } = useUserSession();
  const navigate = useNavigate();

  useEffect(() => {
    const createOrder = async () => {
      setIsLoading(true);
      await createOrderApi(
        userSession?.token.accessToken ?? "",
        activeWorkspace?.workspaceId ?? "",
        fullOrder,
      ).then((res) => {
        if (res) {
          navigate({
            pathname: "/orders/scheduled",
            search: createSearchParams({
              created: "1",
            }).toString(),
          });
        } else {
        }
      });
      setIsLoading(false);
    };
    if (create) {
      createOrder();
    }
  }, [fullOrder, create, navigate, activeWorkspace, userSession]);

  const set = (key: string, val: string) => {
    setFullOrder((fullOrder) => ({ ...fullOrder, [key]: val }) as Order);
  };

  const stopTypes = () => {
    const deliveryStops = props.order.cargo.map(
      (x) => x.itinerary?.deliveryStopNumber,
    );
    const pickupStops = props.order.cargo.map(
      (x) => x.itinerary?.pickupStopNumber,
    );

    return {
      deliveryStops: order.route.filter((x) =>
        deliveryStops.includes(x.stopNumber),
      ),
      pickupStops: order.route.filter((x) =>
        pickupStops.includes(x.stopNumber),
      ),
    };
  };

  const order = props.order;
  const customerName = order.customer.isCompany
    ? order.customer.name
    : order.customer.firstName + " " + order.customer.lastName;

  const calculateTotalVolume = (products: IOrderProduct[]) => {
    const total = products.reduce(
      (partialSum, item) =>
        partialSum + item.quantity > 0
          ? (item.length / 100) *
            (item.height / 100) *
            (item.width / 100) *
            item.quantity
          : 0,
      0,
    );
    return total > 0 && typeof total === "number" ? total : 0;
  };

  const calculateTotalWeight = (products: IOrderProduct[]) => {
    const total = products.reduce(
      (partialSum, item) =>
        partialSum + item.quantity > 0 ? item.weight * item.quantity : 0,
      0,
    );
    return total > 0 && typeof total === "number" ? total : 0;
  };

  const validateAndCreate = async () => {
    set("comment", comment);
    set("workspaceId", activeWorkspace?.workspaceId || "");
    setCreate(true);
  };

  return (
    <div>
      <ul className="w-full divide-y divide-gray-200 dark:divide-gray-700 dark:text-white">
        <div className="pb-2 pt-4">
          <div className="grid grid-cols-2 justify-between ">
            <p>{t("create.driver_selection.summary.date")}</p>
            <p className="text-end">
              {(order.route.at(0)?.stopDate.toDate() as Date)?.toLocaleDateString() ??
                ""}
            </p>
          </div>
        </div>
        <div className="pb-2 pt-4">
          <div className="grid grid-cols-2 justify-between">
            <p>{t("create.driver_selection.summary.customer")}</p>
            <p className="text-end">{customerName}</p>
          </div>
        </div>
        <div className="pb-2 pt-4">
          <div className="grid grid-cols-2 justify-between">
            <p>{t("create.driver_selection.summary.pick_up")}</p>
            <p className="text-end">
              {order.route.at(0)?.location.addressLine}
            </p>
          </div>
        </div>
        <div className="pb-2 pt-4">
          <div className="grid grid-cols-2 justify-between">
            <p>{t("create.driver_selection.summary.delivery")}</p>
            <div>
              {stopTypes().deliveryStops.map((r) => {
                return (
                  <p key={r?.stopNumber} className="text-end">
                    {r.location.addressLine}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        <div className="pb-2 pt-4">
          <div className="grid grid-cols-2 justify-between">
            <p>{t("create.driver_selection.summary.total_weight")}</p>
            <p className="text-end">{calculateTotalWeight(order.cargo)} kg</p>
          </div>
        </div>
        <div className="pb-2 pt-4">
          <div className="grid grid-cols-2 justify-between">
            <p>{t("create.driver_selection.summary.total_volume")}</p>
            <p className="text-end">
              {calculateTotalVolume(order.cargo)} m<sup>3</sup>
            </p>
          </div>
        </div>
      </ul>
      <div>
        <p className="text-lg font-semibold text-gray-900 mt-6 mb-4">
          {t("create.comment_title")}
        </p>
        <Textarea
          style={{ height: "160px" }}
          className="bg-white dark:bg-white dark:text-gray-900 text-gray-900 box-border"
          id="comment"
          name="comment"
          placeholder={t("create.comment_ph")}
          helperText={t("create.comment_helpertext")}
          required
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <div className={"border-0 pt-4 gap-4 justify-end"}>
        <div className={"flex gap-4 justify-end"}>
          <Button color="gray" onClick={() => props.close()}>
            {t("common:close")}
          </Button>
          {!loading && (
            <Button color={"mainGreen"} onClick={validateAndCreate}>
              <HiCheck className="mr-2 h-5 w-5" />
              {t("create.create_order_button")}
            </Button>
          )}
          {loading && (
            <Button color="mainGreen">
              <Spinner size={"sm"} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
