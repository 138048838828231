import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  StandardForm,
  StyledSelect,
  CustomStyledSelect,
  StyledTextArea,
  StyledTextInput,
} from "../..";
import {
  IParameter,
  IRouteItem,
  IProductPricing,
  IOrderProduct,
  IOrderProductItinerary,
} from "../../../types";
import { IPriceModel } from "../../../types/Product";
import {
  validateHasValue,
  validateNumberBetween,
} from "../../../helpers/validationHelper";

export interface ProductCardProps {
  item: IOrderProduct;
  route: IRouteItem[];
  removable?: boolean;
  validationFailed?: boolean;
  productTypes: IParameter[];
  productNumber: number;
  update: (product: IOrderProduct) => void;
  remove: () => void;
}
export interface ProductCardRef {
  updateProductCard: () => IOrderProduct;
}

interface ProductAction {
  type: string;
  payload: { key: string; value: any };
}

const productReducer = (
  state: IOrderProduct,
  action: ProductAction,
): IOrderProduct => {
  switch (action.type) {
    case "UPDATE_PRODUCT_FIELD":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "UPDATE_ITINERARY":
      return {
        ...state,
        itinerary: {
          ...state.itinerary,
          [action.payload.key]: action.payload.value,
        } as IOrderProductItinerary,
      };

    case "UPDATE_PRICING":
      return {
        ...state,
        pricing: action.payload.value,
      };
    default:
      return state;
  }
};

export const ProductCard = forwardRef(
  (props: ProductCardProps, ref: ForwardedRef<ProductCardRef>) => {
    const { t } = useTranslation(["orders", "validation"]);
    const [currentProductItem, dispatch] = useReducer(productReducer, {
      ...props.item,
    });

    /**
     * Exposed to the parent so it can be called through ref.
     */
    useImperativeHandle(ref, () => ({
      updateProductCard() {
        return currentProductItem;
      },
    }));

    return (
      <Card
        type="dashed"
        bgColorClass={
          currentProductItem.productId
            ? "bg-lgb-green-100 dark:bg-lgb-on-dark-background"
            : "bg-lgb-yellow-100 dark:bg-lgb-on-dark-background"
        }
        borderClass={
          currentProductItem.productId
            ? "dark:border-lgb-green-400 border-1"
            : "dark:border-lgb-yellow-400 border-1"
        }
      >
        <div>
          <div className="flex justify-between mb-4 items-center">
            <div className="flex justify-start items-center">
              <span className="border bg-lgb-grey-100 border-lgb-grey-300 px-4 py-1 font-bold rounded text-sm">
                {t("create.product_management.product")} {props.productNumber}
              </span>
              {currentProductItem.productId === undefined && (
                <p className="pl-4 text-lgb-grey-400">
                  ({t("create.product_management.not_saved")})
                </p>
              )}
            </div>
            <div className="flex items-center gap-4">
              <span className="font-medium">{t("common:quantity")}</span>
              <StyledTextInput
                id="quantity"
                type="number"
                defaultValue={currentProductItem.quantity}
                onInputChanged={(e) =>
                  dispatch({
                    type: "UPDATE_PRODUCT_FIELD",
                    payload: { key: "quantity", value: e },
                  })
                }
                validation={{
                  id: "quantity",
                  show: props.validationFailed || false,
                  isInvalid:
                    !currentProductItem.quantity ||
                    currentProductItem.quantity <= 0,
                  errorMessage: t("common:errors.fields.number"),
                }}
              />
            </div>
          </div>
          <StandardForm
            hideButtons
            showHeader={false}
            grid_style="grid grid-cols-4 grid-rows-4 gap-4"
            fields={[
              {
                id: "descriptionShort",
                label: t("common:title"),
                grid_style: "col-span-2",
                input: (
                  <StyledTextInput
                    id="descriptionShort"
                    disabled={currentProductItem.productId !== undefined}
                    placeholder={t("common:placeholder.title")}
                    defaultValue={currentProductItem.descriptionShort ?? ""}
                    onInputChanged={(e) =>
                      dispatch({
                        type: "UPDATE_PRODUCT_FIELD",
                        payload: { key: "descriptionShort", value: e },
                      })
                    }
                    validation={{
                      id: "descriptionShort",
                      show: props.validationFailed || false,
                      isInvalid: !(
                        currentProductItem.descriptionShort.length > 3
                      ),
                      errorMessage: t("validation:minimum_length").replace(
                        "_",
                        "3",
                      ),
                    }}
                  />
                ),
              },
              {
                id: "weight",
                label: t("common:weight"),
                grid_style: "col-start-1 row-start-2",
                input: (
                  <StyledTextInput
                    id="weight"
                    disabled={currentProductItem.productId !== undefined}
                    placeholder={t("common:placeholder.weight")}
                    defaultValue={
                      currentProductItem.weight === 0
                        ? undefined
                        : currentProductItem.weight
                    }
                    type="number"
                    onInputChanged={(e) =>
                      dispatch({
                        type: "UPDATE_PRODUCT_FIELD",
                        payload: { key: "weight", value: +e },
                      })
                    }
                    validation={{
                      id: "weight",
                      show: props.validationFailed || false,
                      isInvalid: !validateNumberBetween(
                        currentProductItem.weight,
                        1,
                        10000,
                      ),
                      errorMessage: t("validation:product_weight"),
                    }}
                  />
                ),
              },
              {
                id: "length",
                label: t("common:length"),
                grid_style: "col-start-2 row-start-2",
                input: (
                  <StyledTextInput
                    id="length"
                    disabled={currentProductItem.productId !== undefined}
                    placeholder={t("common:placeholder.length")}
                    defaultValue={
                      currentProductItem.length === 0
                        ? undefined
                        : currentProductItem.length
                    }
                    type="number"
                    onInputChanged={(e) =>
                      dispatch({
                        type: "UPDATE_PRODUCT_FIELD",
                        payload: { key: "length", value: +e },
                      })
                    }
                    validation={{
                      id: "length",
                      show: props.validationFailed || false,
                      isInvalid:
                        validateHasValue(
                          currentProductItem.length.toString(),
                        ) &&
                        !validateNumberBetween(
                          currentProductItem.length,
                          1,
                          10000,
                        ),
                      errorMessage: t("validation:product_length"),
                    }}
                  />
                ),
              },
              {
                id: "width",
                label: t("common:width"),
                grid_style: "col-start-1 row-start-3",
                input: (
                  <StyledTextInput
                    id="width"
                    disabled={currentProductItem.productId !== undefined}
                    placeholder={t("common:placeholder.width")}
                    defaultValue={
                      currentProductItem.width === 0
                        ? undefined
                        : currentProductItem.width
                    }
                    type="number"
                    onInputChanged={(e) =>
                      dispatch({
                        type: "UPDATE_PRODUCT_FIELD",
                        payload: { key: "width", value: +e },
                      })
                    }
                    validation={{
                      id: "width",
                      show: props.validationFailed || false,
                      isInvalid:
                        validateHasValue(currentProductItem.width.toString()) &&
                        !validateNumberBetween(
                          currentProductItem.width,
                          1,
                          10000,
                        ),
                      errorMessage: t("common:errors.fields.number"),
                    }}
                  />
                ),
              },
              {
                id: "height",
                label: t("common:height"),
                grid_style: "col-start-2 row-start-3",
                input: (
                  <StyledTextInput
                    id="height"
                    disabled={currentProductItem.productId !== undefined}
                    placeholder={t("common:placeholder.height")}
                    defaultValue={
                      currentProductItem.height === 0
                        ? undefined
                        : currentProductItem.height
                    }
                    type="number"
                    onInputChanged={(e) =>
                      dispatch({
                        type: "UPDATE_PRODUCT_FIELD",
                        payload: { key: "height", value: +e },
                      })
                    }
                    validation={{
                      id: "height",
                      show: props.validationFailed || false,
                      isInvalid:
                        validateHasValue(
                          currentProductItem.height.toString(),
                        ) &&
                        !validateNumberBetween(
                          currentProductItem.height,
                          1,
                          10000,
                        ),
                      errorMessage: t("common:errors.fields.number"),
                    }}
                  />
                ),
              },
              {
                id: "productType",
                label: t("common:productType_label"),
                tooltip: t("common:productType_tooltip"),
                input: (
                  <CustomStyledSelect
                    id="productType"
                    disabled={currentProductItem.productId !== undefined}
                    defaultValue={currentProductItem.productType ?? []}
                    options={props.productTypes.map((type) => ({
                      key: type.id,
                      label: type.code,
                    }))}
                    onSelectChanged={(e) =>
                      dispatch({
                        type: "UPDATE_PRODUCT_FIELD",
                        payload: { key: "productType", value: e },
                      })
                    }
                    validation={{
                      id: "productType",
                      show: props.validationFailed || false,
                      isInvalid:
                        currentProductItem.productType === undefined ||
                        !currentProductItem.productType.length,
                      errorMessage: t("common:errors.fields.not_empty"),
                    }}
                  />
                ),
              },
              {
                id: "pricing.priceModel",
                label: t("common:priceModel_label"),
                tooltip: t("common:priceModel_tooltip"),
                input: (
                  <CustomStyledSelect
                    id="pricing.priceModel"
                    disabled={currentProductItem.productId !== undefined}
                    defaultValue={currentProductItem.pricing?.priceModel ?? []}
                    isMultiple
                    options={[
                      {
                        key: IPriceModel.FIXED,
                        label: t("common:price_model.fixed.title"),
                        description: t("common:price_model.fixed.description"),
                      },
                      {
                        key: IPriceModel.VOLUME,
                        label: t("common:price_model.volume.title"),
                        description: t("common:price_model.volume.description"),
                      },
                      {
                        key: IPriceModel.WEIGHT,
                        label: t("common:price_model.weight.title"),
                        description: t("common:price_model.weight.description"),
                      },
                      {
                        key: IPriceModel.DISTANCE,
                        label: t("common:price_model.distance.title"),
                        description: t(
                          "common:price_model.distance.description",
                        ),
                      },
                    ]}
                    onSelectChanged={(e) => {
                      let pricing = (currentProductItem.pricing ??
                        {}) as IProductPricing;
                      pricing.priceModel = (e ?? []) as IPriceModel[];
                      dispatch({
                        type: "UPDATE_PRICING",
                        payload: { key: "pricing", value: pricing },
                      });
                    }}
                    validation={{
                      id: "pricing.priceModel",
                      show: props.validationFailed || false,
                      isInvalid:
                        currentProductItem.pricing === undefined ||
                        currentProductItem.pricing.priceModel === undefined ||
                        currentProductItem.pricing.priceModel.length === 0,
                      errorMessage: t("common:errors.fields.not_empty"),
                    }}
                  />
                ),
              },
              {
                id: "pickupStopNumber",
                label: t("common:pickupLocation"),
                tooltip: t("common:pickupLocation_tooltip"),
                grid_style: "col-start-3 row-start-4",
                input: (
                  <StyledSelect
                    id="pickupStopNumber"
                    defaultValue={
                      props.route.at(0)?.stopNumber.toString() ?? ""
                    }
                    options={props.route.map((item) => ({
                      key: String(item.stopNumber),
                      label: item.location?.addressLine ?? "",
                      value: item.stopNumber.toString(),
                    }))}
                    onSelectChanged={(e) =>
                      dispatch({
                        type: "UPDATE_ITINERARY",
                        payload: {
                          key: "pickupStopNumber",
                          value: parseInt(e),
                        },
                      })
                    }
                    validation={{
                      id: "pickupStopNumber",
                      show: props.validationFailed || false,
                      isInvalid:
                        currentProductItem.itinerary !== undefined &&
                        currentProductItem.itinerary.deliveryStopNumber <=
                          currentProductItem.itinerary.pickupStopNumber,
                      errorMessage: t("common:errors.fields.itinerary_order"),
                    }}
                  />
                ),
              },
              {
                id: "deliveryStopNumber",
                label: t("common:deliveryLocation"),
                tooltip: t("common:deliveryLocation_tooltip"),
                grid_style: "col-start-4 row-start-4",
                input: (
                  <StyledSelect
                    id="deliveryStopNumber"
                    defaultValue={
                      props.route.at(-1)?.stopNumber.toString() ?? ""
                    }
                    options={props.route.map((item, idx) => ({
                      key: idx.toString(),
                      label: item.location?.addressLine ?? "",
                      value: idx.toString(),
                    }))}
                    onSelectChanged={(e) =>
                      dispatch({
                        type: "UPDATE_ITINERARY",
                        payload: {
                          key: "deliveryStopNumber",
                          value: parseInt(e),
                        },
                      })
                    }
                    validation={{
                      id: "pickupStopNumber",
                      show: props.validationFailed || false,
                      isInvalid:
                        currentProductItem.itinerary !== undefined &&
                        currentProductItem.itinerary.deliveryStopNumber <=
                          currentProductItem.itinerary.pickupStopNumber,
                      errorMessage: t("common:errors.fields.itinerary_order"),
                    }}
                  />
                ),
              },
              {
                id: "pricing.pricePerCargoUnit",
                label: t("common:price_model.fixed.title"),
                hidden: !currentProductItem.pricing?.priceModel?.includes(
                  IPriceModel.FIXED,
                ),
                input: (
                  <StyledTextInput
                    id="pricing.pricePerCargoUnit"
                    disabled={currentProductItem.productId !== undefined}
                    placeholder={t("common:price_model.fixed.placeholder")}
                    defaultValue={
                      currentProductItem.pricing?.pricePerCargoUnit === 0
                        ? undefined
                        : currentProductItem.pricing?.pricePerCargoUnit
                    }
                    type="number"
                    onInputChanged={(e) => {
                      let pricing = (currentProductItem.pricing ??
                        {}) as IProductPricing;
                      pricing.pricePerCargoUnit = +e;
                      dispatch({
                        type: "UPDATE_PRICING",
                        payload: { key: "pricing", value: pricing },
                      });
                    }}
                    validation={{
                      id: "pricing.pricePerCargoUnit",
                      show: props.validationFailed || false,
                      isInvalid:
                        currentProductItem.pricing !== undefined &&
                        currentProductItem.pricing.priceModel !== undefined &&
                        currentProductItem.pricing.priceModel.includes(
                          IPriceModel.FIXED,
                        ) &&
                        validateHasValue(
                          currentProductItem.pricing.pricePerCargoUnit?.toString(),
                        ) &&
                        !validateNumberBetween(
                          currentProductItem.pricing.pricePerCargoUnit ?? 0,
                          1,
                          10000,
                        ),
                      errorMessage: t("common:errors.fields.number"),
                    }}
                  />
                ),
              },
              {
                id: "pricing.pricePerVolumeUnit",
                label: t("common:price_model.volume.title"),
                hidden: !currentProductItem.pricing?.priceModel?.includes(
                  IPriceModel.VOLUME,
                ),
                input: (
                  <StyledTextInput
                    id="pricing.pricePerVolumeUnit"
                    disabled={currentProductItem.productId !== undefined}
                    placeholder={t("common:price_model.volume.placeholder")}
                    defaultValue={
                      currentProductItem.pricing?.pricePerVolumeUnit === 0
                        ? undefined
                        : currentProductItem.pricing?.pricePerVolumeUnit
                    }
                    type="number"
                    onInputChanged={(e) => {
                      let pricing = (currentProductItem.pricing ??
                        {}) as IProductPricing;
                      pricing.pricePerVolumeUnit = +e;
                      dispatch({
                        type: "UPDATE_PRICING",
                        payload: { key: "pricing", value: pricing },
                      });
                    }}
                    validation={{
                      id: "pricing.pricePerVolumeUnit",
                      show: props.validationFailed || false,
                      isInvalid:
                        currentProductItem.pricing !== undefined &&
                        currentProductItem.pricing.priceModel !== undefined &&
                        currentProductItem.pricing.priceModel.includes(
                          IPriceModel.VOLUME,
                        ) &&
                        validateHasValue(
                          currentProductItem.pricing.pricePerVolumeUnit?.toString(),
                        ) &&
                        !validateNumberBetween(
                          currentProductItem.pricing.pricePerVolumeUnit ?? 0,
                          1,
                          10000,
                        ),
                      errorMessage: t("common:errors.fields.number"),
                    }}
                  />
                ),
              },
              {
                id: "pricing.pricePerWeightUnit",
                label: t("common:price_model.weight.title"),
                hidden: !currentProductItem.pricing?.priceModel?.includes(
                  IPriceModel.WEIGHT,
                ),
                input: (
                  <StyledTextInput
                    id="pricing.pricePerWeightUnit"
                    disabled={currentProductItem.productId !== undefined}
                    placeholder={t("common:price_model.weight.placeholder")}
                    defaultValue={
                      currentProductItem.pricing?.pricePerWeightUnit === 0
                        ? undefined
                        : currentProductItem.pricing?.pricePerWeightUnit
                    }
                    type="number"
                    onInputChanged={(e) => {
                      let pricing = (currentProductItem.pricing ??
                        {}) as IProductPricing;
                      pricing.pricePerWeightUnit = +e;
                      dispatch({
                        type: "UPDATE_PRICING",
                        payload: { key: "pricing", value: pricing },
                      });
                    }}
                    validation={{
                      id: "pricing.pricePerWeightUnit",
                      show: props.validationFailed || false,
                      isInvalid:
                        currentProductItem.pricing !== undefined &&
                        currentProductItem.pricing.priceModel !== undefined &&
                        currentProductItem.pricing.priceModel.includes(
                          IPriceModel.WEIGHT,
                        ) &&
                        validateHasValue(
                          currentProductItem.pricing.pricePerWeightUnit?.toString(),
                        ) &&
                        !validateNumberBetween(
                          currentProductItem.pricing.pricePerWeightUnit ?? 0,
                          1,
                          10000,
                        ),
                      errorMessage: t("common:errors.fields.number"),
                    }}
                  />
                ),
              },
              {
                id: "pricing.pricePerDistanceUnit",
                label: t("common:price_model.distance.title"),
                hidden: !currentProductItem.pricing?.priceModel?.includes(
                  IPriceModel.DISTANCE,
                ),
                input: (
                  <StyledTextInput
                    id="pricing.pricePerDistanceUnit"
                    disabled={currentProductItem.productId !== undefined}
                    placeholder={t("common:price_model.distance.placeholder")}
                    defaultValue={
                      currentProductItem.pricing?.pricePerDistanceUnit === 0
                        ? undefined
                        : currentProductItem.pricing?.pricePerDistanceUnit
                    }
                    type="number"
                    onInputChanged={(e) => {
                      let pricing = (currentProductItem.pricing ??
                        {}) as IProductPricing;
                      pricing.pricePerDistanceUnit = +e;
                      dispatch({
                        type: "UPDATE_PRICING",
                        payload: { key: "pricing", value: pricing },
                      });
                    }}
                    validation={{
                      id: "pricing.pricePerDistanceUnit",
                      show: props.validationFailed || false,
                      isInvalid:
                        currentProductItem.pricing !== undefined &&
                        currentProductItem.pricing.priceModel !== undefined &&
                        currentProductItem.pricing.priceModel.includes(
                          IPriceModel.DISTANCE,
                        ) &&
                        validateHasValue(
                          currentProductItem.pricing.pricePerDistanceUnit?.toString(),
                        ) &&
                        !validateNumberBetween(
                          currentProductItem.pricing.pricePerDistanceUnit ?? 0,
                          1,
                          10000,
                        ),
                      errorMessage: t("common:errors.fields.number"),
                    }}
                  />
                ),
              },
              {
                id: "description",
                label: t("inventory:product_description"),
                grid_style: "col-span-2 row-span-3 col-start-3 row-start-1",
                input: (
                  <StyledTextArea
                    id="description"
                    defaultValue={currentProductItem.description ?? ""}
                    onInputChanged={(e) =>
                      dispatch({
                        type: "UPDATE_PRODUCT_FIELD",
                        payload: { key: "description", value: e },
                      })
                    }
                  />
                ),
              },
            ]}
          />
          {props.removable && (
            <p
              className="pt-6 text-sm font-normal underline cursor-pointer"
              onClick={props.remove}
            >
              {t("create.product_management.remove")}
            </p>
          )}
        </div>
      </Card>
    );
  },
);

export default ProductCard;
