/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, CustomFlowbiteTheme, Tooltip } from "flowbite-react";
import {
  useState,
  useMemo,
  useEffect,
  useRef,
  useImperativeHandle,
  ForwardedRef,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePlus } from "react-icons/hi";
import {
  Dialog,
  DialogNotify,
  DropdownSearch,
  DropdownSearchListItem,
  SavedProducts,
} from "../../../../components";
import isSmallScreen from "../../../../helpers/is-small-screen";
import { ProductModal } from "../../../../components";
import { Product, OrderProduct, Parameter } from "../../../../models";
import {
  ParameterType,
  IProduct,
  IRouteItem,
  IOrderProduct,
} from "../../../../types";
import { useWorkspace } from "../../../../context/WorkspaceContext";
import { useCache } from "../../../../context/CacheContext";
import { useProducts, useParameters } from "../../../../hooks";
import { TableSkeletonsByAmount } from "../../../../components/atoms/Skeleton/TableSkeleton";
import ProductCard, {
  ProductCardRef,
} from "../../../../components/molecules/Cards/ProductCard";
import { HiTrash } from "react-icons/hi";

export interface ProductSelectionProps {
  orderItems: IOrderProduct[];
  route: IRouteItem[];
  hideSearch?: boolean;
  validationFailed: boolean;
  update: (products: IOrderProduct[]) => void;
}
export interface ProductSelectionRef {
  updateOrderProducts: () => void;
}
const ProductManagement = forwardRef(
  (props: ProductSelectionProps, ref: ForwardedRef<ProductSelectionRef>) => {
    const { t } = useTranslation(["orders", "validation"]);
    const [currentOrderItems, setCurrentOrderItems] = useState(
      props.orderItems,
    );
    const [showNewProductDialog, setShowNewProductDialog] = useState(false);
    const { activeWorkspace } = useWorkspace();
    const { updateCacheKey } = useCache();
    const [productCategories, setProductCategories] = useState(
      [] as Parameter[],
    );
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [toBeRemoved, setToBeRemoved] = useState(-1);

    const [searchText, setSearchText] = useState<string>("");
    const [searchResults, setSearchResults] = useState([] as IOrderProduct[]);
    const [showAllProducts, setShowAllProducts] = useState(false);
    const productCardRefs = useRef<(ProductCardRef | null)[]>([]); // Allow ref to be null initially
    const [newProducts, setNewProducts] = useState<IOrderProduct[]>(
      props.orderItems,
    );

    const query = useProducts(activeWorkspace?.workspaceId ?? "");
    const categoriesQuery = useParameters(
      activeWorkspace?.workspaceId ?? "",
      ParameterType.ProductType,
    );

    const products = useMemo(() => query.data ?? [], [query]) as OrderProduct[];

    const isQueryLoading = useMemo(
      () =>
        query.isLoading ||
        query.isRefetching ||
        categoriesQuery.isLoading ||
        categoriesQuery.isRefetching,
      [query, categoriesQuery],
    );

    const categories = useMemo(
      () => categoriesQuery.data ?? [],
      [categoriesQuery],
    );

    useEffect(() => {
      setCurrentOrderItems(currentOrderItems);
    }, [currentOrderItems]);

    useEffect(() => {
      if (!isQueryLoading) {
        setProductCategories(categories);
      }
    }, [isQueryLoading, categories]);

    useEffect(() => {
      if (searchText.length) {
        let res =
          products?.filter(
            (x) =>
              !currentOrderItems.map((x) => x.description).includes(x.id) &&
              (x.description + " " + x.descriptionShort)
                ?.toLowerCase()
                .includes(searchText.toLowerCase()),
          ) || [];
        setSearchResults(res.slice(0, 3));
      } else {
        setSearchResults([]);
      }
    }, [searchText, products, currentOrderItems]);

    const CreateProductDialog = (
      <ProductModal
        data={Product.default()}
        productTypes={productCategories}
        isShowing={showNewProductDialog}
        onConfirm={(formData: IProduct) => {
          Product.create(activeWorkspace?.workspaceId ?? "", formData);
          setShowNewProductDialog(false);
          updateCacheKey();
        }}
        onCancel={() => setShowNewProductDialog(false)}
      />
    );

    /**
     * Exposed to the parent so it can be called through ref.
     */
    useImperativeHandle(ref, () => ({
      updateOrderProducts,
    }));

    const updateOrderProducts = () => {
      const updatedProducts = productCardRefs.current.map(
        (ref) => (ref ? ref.updateProductCard() : null), // Trigger update for each ProductCard
      );

      props.update(
        updatedProducts.filter((prod) => prod !== null) as IOrderProduct[],
      ); // Use the latest state
    };

    function handleSearch(val: string) {
      setSearchText(val);
    }

    function productIsEmpty(product: IOrderProduct) {
      let comparisonProduct = getEmptyProduct();
      return JSON.stringify(product) === JSON.stringify(comparisonProduct);
    }

    function addSavedProduct(id: string) {
      let existingItems = [...currentOrderItems] as OrderProduct[];

      // find product and add new items
      let product = products?.find((x) => x.id === id);
      let savedProduct = product as OrderProduct;
      savedProduct.id = (existingItems.length + 1).toString();
      savedProduct.productId = product?.id;
      savedProduct.quantity = 1;
      savedProduct.itinerary = OrderProduct.defaultItinerary();
      savedProduct.itinerary.pickupStopNumber =
        props.route.at(0)?.stopNumber ?? 0;
      savedProduct.itinerary.deliveryStopNumber =
        props.route.at(-1)?.stopNumber ?? 0;

      // Get default value for itinerary
      if (existingItems && existingItems.length > 0) {
        savedProduct.itinerary = existingItems[0].itinerary;
      }
      //check if empty product exists
      let emptyProductIndex = existingItems.findIndex((x) => productIsEmpty(x));
      emptyProductIndex >= 0
        ? (existingItems[emptyProductIndex] = savedProduct)
        : (existingItems = existingItems.concat([savedProduct]));
      setCurrentOrderItems(existingItems as OrderProduct[]);

      setSearchText("");
      if (showAllProducts) {
        toggleShowAllDialog();
      }
    }

    function toggleShowAllDialog() {
      setSearchText("");
      setShowAllProducts(!showAllProducts);
    }

    function addEmptyProduct() {
      const newProducts = productCardRefs.current
        .map((ref) => (ref ? ref.updateProductCard() : null))
        .filter((product): product is IOrderProduct => product !== null);
      if (newProducts.length) {
        let emptyProduct = getEmptyProduct();
        newProducts.push(emptyProduct);
        setCurrentOrderItems(newProducts);
      }
    }

    function getEmptyProduct() {
      let emptyProduct = OrderProduct.defaultProduct();
      emptyProduct.itinerary = OrderProduct.defaultItinerary();
      emptyProduct.itinerary.pickupStopNumber =
        props.route.at(0)?.stopNumber ?? 0;
      emptyProduct.itinerary.deliveryStopNumber =
        props.route.at(-1)?.stopNumber ?? 0;
      return emptyProduct as OrderProduct;
    }

    const removeProduct = (productIdx: number, forceRemoval = false) => {
      let orderItems = [...currentOrderItems] as OrderProduct[];
      let item = orderItems.at(productIdx);
      if (item) {
        if (!forceRemoval && !productIsEmpty(item)) {
          // If productItem was modified, we ask for confirmation before removal
          setToBeRemoved(productIdx);
          setShowRemoveDialog(!showRemoveDialog);
        } else {
          if (orderItems.length === 1) {
            orderItems[0] = getEmptyProduct();
          } else {
            orderItems.splice(productIdx, 1);
          }
          setCurrentOrderItems(orderItems);
          if (forceRemoval) {
            setToBeRemoved(-1);
            setShowRemoveDialog(!showRemoveDialog);
          }
        }
      }
    };

    const updateProduct = (productIdx: number, product: IOrderProduct) => {
      let updatedProducts = [...currentOrderItems] as IOrderProduct[];
      updatedProducts[productIdx] = product;
      setNewProducts(updatedProducts);
    };

    let rows = [];
    for (let i = 0; i < currentOrderItems.length; i++) {
      rows.push(
        <div className="mb-4">
          <ProductCard
            ref={(el) => (productCardRefs.current[i] = el)}
            key={
              currentOrderItems.map((x) => x.id).toString() +
              currentOrderItems.length +
              i +
              currentOrderItems.map((x) => x.descriptionShort)
            }
            productNumber={i + 1}
            productTypes={productCategories}
            item={currentOrderItems[i]}
            route={props.route}
            validationFailed={props.validationFailed}
            removable={
              (currentOrderItems.length === 1 &&
                !productIsEmpty(currentOrderItems[0])) ||
              currentOrderItems.length > 1
            }
            update={(p) => updateProduct(i, p)}
            remove={() => removeProduct(i)}
          />
        </div>,
      );
    }

    const showAllProductsDialog = (
      <Dialog
        title={t("create.product_management.add_saved_product_title")}
        show={showAllProducts}
        toggleModal={() => toggleShowAllDialog()}
        hideButtons
        content={
          <SavedProducts
            products={
              products?.filter(
                (x) => !currentOrderItems.map((x) => x.id).includes(x.id),
              ) || ([] as IOrderProduct[])
            }
            currentSelected={currentOrderItems}
            addProduct={addSavedProduct}
            close={toggleShowAllDialog}
          />
        }
      />
    );

    return (
      <div>
        {!props.hideSearch && (
          <div className="pb-4">
            <DropdownSearch
              placeholder={t("create.product_management.search_ph")}
              inputChanged={(val) => handleSearch(val)}
              showResults={searchResults.length > 0}
              childrenAbsolute
              buttonClick={() => setShowNewProductDialog(true)}
              buttonText={t("create.product_management.new_product_button")}
            >
              {query.isLoading && <TableSkeletonsByAmount quantity={3} />}
              {!query.isLoading && searchResults.length > 0 && (
                <div>
                  {searchResults.map((product: IOrderProduct) => {
                    return (
                      <DropdownSearchListItem
                        key={product.id}
                        id={product.id}
                        title={product.descriptionShort} //title
                        subtitle={product.descriptionShort || ""} //type
                        buttonClickEvent={addSavedProduct}
                        inlineSubtitle
                      ></DropdownSearchListItem>
                    );
                  })}
                  {searchResults.length === 3 && (
                    <p
                      className="pt-4 text-md underline font-normal cursor-pointer hover:text-lgb-primary"
                      onClick={() => toggleShowAllDialog()}
                    >
                      {t("create.product_management.see_all")}
                    </p>
                  )}
                </div>
              )}
            </DropdownSearch>
          </div>
        )}

        <div className="w-full">{rows}</div>

        <Tooltip
          className="ml-11"
          theme={selectedTheme}
          style="light"
          content={
            <div className="w-full divide-y divide-gray-200 dark:divide-gray-700 z-10">
              <div
                className="py-2 cursor-pointer hover:text-lgb-primary dark:hover:text-white"
                onClick={() => addEmptyProduct()}
              >
                <div className="flex items-center space-x-4">
                  <div className="flex gap-2">
                    <p className={"text-md font-medium"}>
                      {t("create.product_management.add_empty_product")}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="py-2 cursor-pointer hover:text-lgb-primary dark:hover:text-white"
                onClick={() => toggleShowAllDialog()}
              >
                <div className="flex items-center space-x-4">
                  <div className="flex gap-2">
                    <p className={"text-md font-medium"}>
                      {t("create.product_management.add_saved_product_title")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <Button
            fullSized={isSmallScreen()}
            className="relative mt-4"
            color="light"
            type="submit"
          >
            <HiOutlinePlus className="h-5 w-5" />
          </Button>
        </Tooltip>

        {CreateProductDialog}
        {showAllProductsDialog}
        <DialogNotify
          show={showRemoveDialog}
          isLoading={false}
          icon={<HiTrash size={"24px"} className="text-gray-500" />}
          buttonCloseText={t("common:close")}
          buttonConfirmColor="red300"
          buttonConfirmText={t("create.product_management.remove_confirm")}
          title={t("create.product_management.remove_text")}
          toggleModal={() => {
            setToBeRemoved(-1);
            setShowRemoveDialog(!showRemoveDialog);
          }}
          confirmButton={() => removeProduct(toBeRemoved, true)}
        />
      </div>
    );
  },
);
const selectedTheme: CustomFlowbiteTheme["tooltip"] = {
  base: "absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-medium shadow-lg",
  arrow: {
    base: "hidden",
  },
  style: {
    light:
      "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white",
  },
};
export default ProductManagement;
