import { FC } from "react";
import { OrderStatus } from "../../../types/order/IOrder";
import {
  HiCheck,
  HiPlus,
  HiOutlineClipboardList,
  HiClock,
  HiX,
  HiFastForward,
} from "react-icons/hi";
import InfoCard from "./InfoCard";
import { useTranslation } from "react-i18next";

interface OrderStatusCardProps {
  status?: OrderStatus;
}

const OrderStatusCard: FC<OrderStatusCardProps> = (props) => {
  const { t } = useTranslation("orders");
  if (props.status) {
    switch (props.status) {
      case OrderStatus.Completed:
        return (
          <InfoCard
            icon={
              <div
                className="bg-lgb-green-400 rounded-xl relative"
                style={{ width: "20px", height: "20px" }}
              >
                <HiCheck
                  size={"18px"}
                  className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            }
            title={t("orders:completed_orders.info_title")}
            description={t("orders:completed_orders.info_description")}
            titleClass="text-lgb-green-400"
            descriptionClass="text-lgb-green-400"
            containerClass="mt-6 bg-lgb-green-100 dark:bg-lgb-green-200"
          />
        );
      case OrderStatus.Scheduled:
        return (
          <InfoCard
            icon={
              <div
                className="bg-lgb-grey-600 rounded-xl relative"
                style={{ width: "24px", height: "24px" }}
              >
                <HiClock
                  size={"18px"}
                  className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            }
            title={t("orders:scheduled_orders.info_title")}
            description={t("orders:scheduled_orders.info_description")}
            titleClass="text-lgb-grey-600"
            descriptionClass="text-lgb-grey-600"
            containerClass="mt-6 bg-lgb-grey-100"
          />
        );
      case OrderStatus.InProgress:
        return (
          <InfoCard
            icon={
              <div
                className="bg-lgb-blue-400 rounded-xl relative"
                style={{ width: "20px", height: "20px" }}
              >
                <HiFastForward
                  size={"18px"}
                  className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            }
            title={t("orders:ongoing_orders.info_title")}
            description={t("orders:ongoing_orders.info_description")}
            titleClass="text-lgb-blue-400"
            descriptionClass="text-lgb-blue-400"
            containerClass="mt-6 bg-lgb-blue-100 dark:bg-lgb-blue-200"
          />
        );
      case OrderStatus.New:
        return (
          <InfoCard
            icon={
              <div
                className="bg-lgb-yellow-400 rounded-xl relative"
                style={{ width: "20px", height: "20px" }}
              >
                <HiClock
                  size={"18px"}
                  className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            }
            title={t("orders:scheduled_orders.info_title")}
            description={t("orders:scheduled_orders.info_description")}
            titleClass="text-lgb-yellow-400"
            descriptionClass="text-lgb-yellow-400"
            containerClass="mt-6 bg-lgb-yellow-100 dark:bg-lgb-yellow-200"
          />
        );
      case OrderStatus.Cancelled:
        return (
          <InfoCard
            icon={
              <div
                className="bg-lgb-red-400 rounded-xl relative"
                style={{ width: "20px", height: "20px" }}
              >
                <HiX
                  size={"18px"}
                  className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            }
            title={t("orders:cancelled_orders.info_title")}
            description={t("orders:cancelled_orders.info_description")}
            titleClass="text-lgb-red-400"
            descriptionClass="text-lgb-red-400"
            containerClass="mt-6 bg-lgb-red-100 dark:bg-lgb-red-100"
          />
        );
      case OrderStatus.Draft:
        return (
          <InfoCard
            icon={
              <div
                className="bg-lgb-grey-600 rounded-xl relative"
                style={{ width: "20px", height: "20px" }}
              >
                <HiClock
                  size={"18px"}
                  className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            }
            title={t("orders:draft_orders.info_title")}
            description={t("orders:draft_orders.info_description")}
            titleClass="text-lgb-grey-600"
            descriptionClass="text-lgb-grey-600"
            containerClass="mt-6 bg-lgb-grey-100"
          />
        );
    }
  } else return <></>;
};

export default OrderStatusCard;
