import { Route, Routes } from "react-router-dom";
import "./App.css";
import {
  Dashboard,
  OrderOutlet,
  WorkspacePage,
  UserPage,
  ProfilePage,
  CompaniesPage,
  WorkspaceUserPage,
  DriversPage,
  VehiclesPage,
  ConfigurationPage,
  Customers,
  ProductsPage,
  MyWorkspaces,
  CreateOrder,
  ScheduledOrderPage,
  OngoingOrdersPage,
  CompletedOrdersPage,
  AllOrdersPage,
  Invoices,
  Locations,
} from "./pages";
import {
  LoginPage,
  LogoutPage,
  SignUpPage,
  PasswordResetPage,
  WorkspaceLoader,
} from "./pages/auth";
import { NotFoundPage, ServerErrorPage, MaintenancePage } from "./pages/utils";
import { useDarkMode } from "./context/DarkModeContext";
import { Suspense } from "react";
import CouponPage from "./pages/CouponPage";
import { DriverMapPage } from "./pages/orders/DriverMap";

function App() {
  const { isDarkMode } = useDarkMode();

  if (isDarkMode) {
    document.getElementById("root")?.classList.add("dark");
    document
      .getElementsByTagName("body")[0]
      .classList.remove("light-scrollbar");
    document.getElementsByTagName("body")[0].classList.add("dark-scrollbar");
  } else {
    document.getElementById("root")?.classList.remove("dark");
    document.getElementsByTagName("body")[0].classList.remove("dark-scrollbar");
    document.getElementsByTagName("body")[0].classList.add("light-scrollbar");
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/logout" element={<LogoutPage />}></Route>
        <Route path="/signup" element={<SignUpPage />}></Route>
        <Route path="/load_workspace" element={<WorkspaceLoader />}></Route>
        <Route path="/reset_password" element={<PasswordResetPage />}></Route>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/administration">
          <Route path="users" element={<UserPage />} />
          <Route path="workspaces" element={<WorkspacePage />} />
        </Route>
        <Route path="/orders" element={<OrderOutlet />}>
          <Route path="new" element={<CreateOrder />} />
          <Route path="all" element={<AllOrdersPage />} />
          <Route path="scheduled" element={<ScheduledOrderPage />} />
          <Route path="ongoing" element={<OngoingOrdersPage />} />
          <Route path="completed" element={<CompletedOrdersPage />} />
        </Route>
        <Route path="/invoices" element={<Invoices />} />
        <Route path="map" element={<DriverMapPage />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/resources">
          <Route path="drivers" element={<DriversPage />} />
          <Route path="vehicles" element={<VehiclesPage />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="locations" element={<Locations />} />
        </Route>

        <Route path="/workspace">
          <Route path="resource-types" element={<ConfigurationPage />} />
          <Route path="users" element={<WorkspaceUserPage />} />
          <Route path="companies" element={<CompaniesPage />} />
          <Route path="coupons" element={<CouponPage />} />
        </Route>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/my_workspaces" element={<MyWorkspaces />} />
        <Route path="/maintenance" element={<MaintenancePage />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="/500" element={<ServerErrorPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
