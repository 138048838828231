import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import { Timestamp, ICustomer, IAddress } from "../types";
import { query, doc } from "firebase/firestore";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Customer implements ICustomer {
  id: string;
  workspaceId: string;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  contactEmail?: string | null;
  invoiceEmail?: string | null;
  phoneNumber?: string | null;
  primaryAddress?: IAddress | null;
  isCompany?: boolean | false;
  organizationNumber?: string | null;
  socialSecurityNumber?: string | null;
  createdAt?: Timestamp;

  static collectionName = "customers";

  static converter = {
    toFirestore(customer: Customer) {
      return customer.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as ICustomer;
      return new Customer({ ...data, id: snapshot.id });
    },
  };

  static createId() {
    return firestore.collection(Customer.collectionName).doc().id;
  }

  constructor({
    id,
    workspaceId,
    isCompany,
    name,
    firstName,
    lastName,
    organizationNumber,
    socialSecurityNumber,
    primaryAddress,
    phoneNumber,
    contactEmail,
    invoiceEmail,
    createdAt,
  }: ICustomer) {
    this.id = id || "";
    this.workspaceId = workspaceId || "";
    this.isCompany = isCompany || false;
    this.name = name || null;
    this.firstName = firstName || null;
    this.lastName = lastName || null;
    this.organizationNumber = organizationNumber || null;
    this.socialSecurityNumber = socialSecurityNumber || null;
    this.primaryAddress = primaryAddress || null;
    this.phoneNumber = phoneNumber || "";
    this.contactEmail = contactEmail || "";
    this.invoiceEmail = invoiceEmail || "";
    this.phoneNumber = phoneNumber || "";
    this.createdAt = createdAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<ICustomer>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      id: this.id || "",
      workspaceId: this.workspaceId || "",
      isCompany: this.isCompany || false,
      name: this.name || null,
      firstName: this.firstName || null,
      lastName: this.lastName || null,
      organizationNumber: this.organizationNumber || null,
      primaryAddress: this.primaryAddress || null,
      phoneNumber: this.phoneNumber || "",
      contactEmail: this.contactEmail || "",
      invoiceEmail: this.invoiceEmail || "",
      createdAt: this.createdAt || null,
    };
  }

  static default = (): Customer => {
    return new Customer({
      id: "",
      workspaceId: "",
      isCompany: true,
      name: "",
      firstName: "",
      lastName: "",
      organizationNumber: "",
      socialSecurityNumber: "",
      primaryAddress: null,
      contactEmail: "",
      invoiceEmail: "",
      phoneNumber: "",
    });
  };

  static getCustomerName(customer: Customer): string {
    if (customer.isCompany) {
      return customer.name || "";
    }
    return `${customer.firstName || ""} ${customer.lastName || ""}`;
  }

  static isNew(data: ICustomer): boolean {
    return data.id.length === 0;
  }

  static get = (id: string) => {
    return doc(
      firestore
        .collection(Customer.collectionName)
        .withConverter(Customer.converter),
      id,
    );
  };

  static list = (workspaceId: string) => {
    return query(
      firestore
        .collection(Customer.collectionName)
        .where("workspaceId", "==", workspaceId)
        .withConverter(Customer.converter),
    );
  };

  static create = async (
    workspaceId: string,
    data: Omit<ICustomer, "id" | "createdAt">,
  ) => {
    const newCustomer = new Customer({
      ...data,
      id: Customer.createId(),
      workspaceId: workspaceId,
      createdAt: Timestamp.now(),
    });

    if (newCustomer.isCompany) {
      newCustomer.firstName = "";
      newCustomer.lastName = "";
      newCustomer.socialSecurityNumber = "";
    } else {
      newCustomer.name = newCustomer.firstName + " " + newCustomer.lastName;
      newCustomer.organizationNumber = "";
    }

    try {
      await firestore
        .collection(Customer.collectionName)
        .doc(newCustomer.id)
        .withConverter(Customer.converter)
        .set(newCustomer, { merge: true });
    } catch (e) {
      console.warn("Create customer failed with error: ", e);
      return false;
    }
    return newCustomer;
  };

  static update = async (customer: Customer, updates: Partial<ICustomer>) => {
    const updateType =
      (customer.clone && customer.clone()) || new Customer({ ...customer });

    updateType.setData({
      ...updates,
    });
    await firestore
      .collection(Customer.collectionName)
      .doc(customer.id)
      .withConverter(Customer.converter)
      .set(updateType, { merge: true });

    return updateType;
  };

  static delete = async (customer: Customer) => {
    return await firestore
      .collection(Customer.collectionName)
      .doc(customer.id)
      .delete();
  };
}

export default Customer;
