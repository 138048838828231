/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, type FC } from "react";
import { SimpleList } from "../SimpleList";
import { useTranslation } from "react-i18next";
import { HiOutlineCalendar } from "react-icons/hi";
import { HiCheck } from "react-icons/hi";
import { Tooltip } from "flowbite-react";
import { FaRegCopy } from "react-icons/fa";
import { Timestamp } from "../../../types";
import { Location } from "../../../models";
import { StaticMap } from "../../organisms/StaticMap";

export interface LocationDetailsProps {
  location: Location | undefined;
  sendInvoice?: () => void;
  edit: () => void;
  delete: () => void;
}

export const LocationDetails: FC<LocationDetailsProps> = (props) => {
  const { t } = useTranslation(["common", "location"]);
  const [hasCopied, setHasCopied] = useState(false);

  return (
    <>
      <div className="xl:flex justify-between items-center gap-4 pt-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {props.location?.displayName}
          </p>
          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      props.location?.displayName?.toString() ?? "",
                    );
                    setHasCopied(true);
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
        <div className="flex gap-2 items-center dark:text-lgb-grey-100 mt-2 xl:mt-0">
          <HiOutlineCalendar size={20} />
          <p>
            {Timestamp.toDisplayDate(
              props.location?.createdAt || Timestamp.now(),
            )}
          </p>
        </div>
      </div>

      {props.location && (
        // TODO: Get location coordinates and display pin on the static map
        // Added hidden class for now.
        <div className="my-6 hidden">
          <StaticMap
            coordinates={{
              lat: 60.398891,
              lng: 5.3204726,
            }}
            height="300px"
          />
        </div>
      )}

      <p className="text-2xl mb-4 mt-6 font-medium dark:text-white">
        {t("common:general")}
      </p>
      <SimpleList
        items={[
          {
            title: t("location:details.address"),
            value: props.location?.addressLine,
          },
          {
            title: t("location:details.city"),
            value: props.location?.city,
          },
          {
            title: t("location:details.postCode"),
            value: props.location?.postCode,
          },
          {
            title: t("location:details.description"),
            value: props.location?.description,
          },
          {
            title: t("location:details.countryCode"),
            value: props.location?.countryCode,
          },
        ]}
      />
    </>
  );
};
