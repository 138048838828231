/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useEffect, useMemo } from "react";
import { PageLayout } from "../../components";
import { useUsers } from "../../hooks";
import { TableSkeleton } from "../../components";
import { StandardTable } from "../../components";
import { ITableRow, Timestamp } from "../../types";
import { useTranslation } from "react-i18next";

export const UserPage: FC = function () {
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const { t } = useTranslation(["common", "user,"]);
  const query = useUsers();

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  const queryData = useMemo(() => query.data ?? [], [query]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        queryData.map((user) => {
          return {
            id: user.id,
            cells: [
              {
                id: "name",
                children: user.displayName ?? user.email,
                showOnSmallScreen: true,
              },
              {
                id: "email",
                children: user.email,
              },
              {
                id: "emailVerification",
                children: (
                  <div className="flex items-center">
                    {user.emailVerified ? (
                      <div className="mr-2 h-2.5 w-2.5 rounded-full bg-green-400"></div>
                    ) : (
                      <div className="mr-2 h-2.5 w-2.5 rounded-full bg-red-400"></div>
                    )}
                    {user.emailVerified ? t("common:yes") : t("common:no")}
                  </div>
                ),
              },
              {
                id: "isAdmin",
                children: (
                  <div className="flex items-center">
                    {user.isAdmin ? t("common:yes") : t("common:no")}
                  </div>
                ),
              },
              {
                id: "createdAt",
                children: Timestamp.toDisplayDate(user.createdAt),
              }
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, queryData, t]);

  return (
    <PageLayout>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="pb-4">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                {t("user:admin_title")}
              </h1>
              <p className="text-lgb-grey-600 text-base pt-2 pb-4">
                {t("user:admin_description")}
              </p>
            </div>
            <div className="overflow-hidden shadow">
              {isLoading ? (
                <TableSkeleton />
              ) : (
                <StandardTable
                  header={[
                    {
                      id: "name",
                      children: t("user:users.name"),
                      showOnSmallScreen: true,
                    },
                    {
                      id: "email",
                      children: t("user:users.email"),
                    },
                    {
                      id: "emailVerification",
                      children: t("user:users.emailVerification"),
                    },
                    {
                      id: "isAdmin",
                      children: t("user:users.isAdmin"),
                    },
                    {
                      id: "createdAt",
                      children: t("common:created_at"),
                    },
                  ]}
                  rows={tableRows}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
