import axios from "axios";
import {
  IWorkspaceBase,
  IWorkspaceAccessBase,
  IOrder,
  Timestamp,
} from "../types";

export const signup = async (token: string) => {
  const response = await apiPost(token, "/api/signup/");
  return response.data;
};

export const createUser = async (
  token: string,
  workspaceId: string,
  user: IWorkspaceAccessBase,
) => {
  const response = await apiPost(
    token,
    `/api/workspaces/${workspaceId}/invite`,
    user,
  );
  return response.data;
};

export const getMyUser = async (token: string) => {
  const response = await apiGet(token, "/api/users/me");
  return response.data;
};

export const createWorkspace = async (
  token: string,
  workspace: IWorkspaceBase,
) => {
  const response = await apiPost(token, "/api/workspaces/", workspace);
  return response.data;
};

export const updateWorkspace = async (
  token: string,
  workspaceId: string,
  workspace: IWorkspaceBase,
) => {
  const response = await apiPut(
    token,
    `/api/workspaces/${workspaceId}`,
    workspace,
  );
  return response.data;
};

export const deleteWorkspace = async (token: string, workspaceId: string) => {
  const response = await apiDelete(token, `/api/workspaces/${workspaceId}`);
  return response.data;
};

export const createOrder = async (
  token: string,
  workspaceId: string,
  order: IOrder,
) => {
  let data: any = order;
  for (let i = 0; i < order.route.length; i++) {
    data.route[i].stopDate = Timestamp.toMoment(order.route[i].stopDate);
  }
  const response = await apiPost(token, "/api/orders/", order, workspaceId);
  return response.data;
};
export const cancelOrder = async (
  token: string,
  workspaceId: string,
  id: string,
) => {
  const response = await apiPost(
    token,
    `/api/orders/${id}/cancel`,
    workspaceId,
  );
  return response.data;
};

export const getOrders = async (token: string, workspaceId: string) => {
  const response = await apiGet(token, `/api/orders/`, workspaceId);
  return response.data;
};

const apiPost = async (
  token: string,
  url: string,
  data: any = {},
  workspaceId?: string,
) => {
  let headers = { Authorization: `Bearer ${token}`, "x-workspace-id": "" };
  if (workspaceId) {
    headers["x-workspace-id"] = workspaceId;
  }
  const request = axios.post(`${baseUrl}${url}`, data, {
    headers: headers,
  });
  return request
    .then((response: any) => response.data)
    .catch((error: any) => console.log(error));
};

const apiPut = async (token: string, url: string, data: any = {}) => {
  const request = axios.put(`${baseUrl}${url}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return request
    .then((response: any) => response.data)
    .catch((error: any) => console.log(error));
};

const apiGet = async (token: string, url: string, workspaceId?: string) => {
  const request = axios.get(`${baseUrl}${url}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Workspace-Id": workspaceId,
    },
  });
  return request
    .then((response: any) => response.data)
    .catch((error: any) => console.log(error));
};

const apiDelete = async (token: string, url: string) => {
  const request = axios.delete(`${baseUrl}${url}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return request
    .then((response: any) => response.data)
    .catch((error: any) => console.log(error));
};

const baseUrl: string =
  process.env.REACT_APP_CLOUD_API_URL || "http://localhost:8080";
