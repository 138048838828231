import { Button } from "flowbite-react";
import type { FC } from "react";
import React from "react";
import { saveAs } from "file-saver";
import { DocumentProps, pdf } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import pdfIcon from "../../../assets/icons/pdfIcon.svg";

interface DownloadPDFButtonProps {
  pdf: React.ReactElement<DocumentProps>;
  fileName: string;
}

export const DownloadPDFButton: FC<DownloadPDFButtonProps> = (
  props: DownloadPDFButtonProps,
) => {
  const { t } = useTranslation(["common"]);

  const downloadPdf = async () => {
    const blob = await pdf(props.pdf).toBlob();
    saveAs(blob, props.fileName);
  };

  return (
    <Button
      onClick={() => {
        downloadPdf();
      }}
      size="sm"
      color="light"
    >
      <img src={pdfIcon} alt="PDF icon" className="mr-2" />
      {t("common:download_pdf")}
    </Button>
  );
};

export default DownloadPDFButton;
